import gql from 'graphql-tag'
import { apolloClient as apolloClientPublic } from '@/vue-apollo-public'
import { apolloClient } from '@/vue-apollo'

export const futureReservationsMapi = async ({
  page,
  weeks,
  hotelId,
  aditionalFields,
  forTicketsMode,
}) => {
  const res = await apolloClient.query({
    query: gql`
      query {
        futureReservationsMapi(page: ${page}, weeks: ${weeks}, hotelId: ${hotelId}, forTicketsMode: ${forTicketsMode}) {
          page
          pages
          hasNext
          hasPrev
          objects {
            id
            confirmationNumber
            partyCode
            room
            agentUsername
            mapiVisit {
              id
              preferredDate
              visitDate
              isApproved
              hasTicketParque
              hasTicketTren
              hasTicketBus
            }
            traveller {
              id
              firstName
              lastName
            }
            travellerObj {
              id
              firstName
              lastName
              gender
              genderStr
              ageCategory
              nationality
              nationalityName
              birthday
              dni
              group
            }
            checkin
            checkout
            ${aditionalFields}
          }
        }
      }
    `,
    variables: null,
  })
  return res.data
}

export const mutationUpdateMapiPreferredDate = async ({
  mapiPreferredDate,
  confirmationNumber,
  legNumber,
}) => {
  const response = await apolloClientPublic.mutate({
    mutation: gql`
      mutation(
        $confirmationNumber: Int!
        $legNumber: Int!,
        $mapiPreferredDate: Date!,
      ) {
        UpdateMapiPreferredDate(
          confirmationNumber: $confirmationNumber,
          legNumber: $legNumber,
          mapiPreferredDate: $mapiPreferredDate,
        ) { result }
      }
    `,
    variables: {
      mapiPreferredDate: mapiPreferredDate,
      confirmationNumber: confirmationNumber,
      legNumber: legNumber,
    }
  })
  return response.data
}

export const mutationUpdateTravellerDataMapi = async ({
  confirmationNumber, id, dni, name, lastName, birthdate, nationality,
}) => {
  const response = await apolloClientPublic.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $dni: String!,
        $confirmationNumber: Int!,
        $name: String!,
        $lastName: String!,
        $birthdate: String!,
        $nationality: String!,
      ) {
        UpdateTravellerDataMapi(
          id: $id,
          dni: $dni,
          confirmationNumber: $confirmationNumber,
          name: $name,
          lastName: $lastName,
          birthdate: $birthdate,
          nationality: $nationality,
        ) { result }
      }
    `,
    variables: {
      id: id,
      dni: dni,
      confirmationNumber: confirmationNumber,
      name: name,
      lastName: lastName,
      birthdate: birthdate,
      nationality: nationality,
    }
  })

  return response.data
}

export const queryGetMapiMessages = async () => {
  const res = await apolloClientPublic.query({
    query: gql`
      query {
        mapiMessages (lang: "es")
      }
    `,
  })
  return res.data.mapiMessages
}


export const mutationSetApprovedMapi = async ({
  mapiVisitId,
  approved,
  applyParty,
}) => {
	const res = await apolloClient.mutate({
    mutation: gql`mutation(
      $mapiVisitId: Int!,
      $approved: Boolean!,
      $applyParty: Boolean!,
    ) {
      SetApprovedMapi(
        mapiVisitId: $mapiVisitId,
        isApproved: $approved,
        applyParty: $applyParty
      ) {
        result
      }
    }`,
    variables: {
      mapiVisitId,
      approved,
      applyParty
    }
  })
  return res.data
}
