const mutations = {
  'SET_TRAVELLER_PROFILE' (state, { travellerProfile }) {
    state.travellerProfile = travellerProfile
  },
  'SET_NATIONALITIES' (state, { nationalities }) {
    state.nationalities = nationalities
  },
  'SET_COUNTRIES' (state, { countries }) {
    state.countries = countries
  },
  'SET_TRANSPORT' (state, { transportsInformation }) {
    state.transportsInformation = transportsInformation
  },
  'SET_TAGS_CONDITIONS' (state, { tagsConditions }) {
    state.tagsConditions = tagsConditions
  },
  'SET_INTERESTS' (state, { interests }) {
    state.interests = interests
  },
  'SET_COMPANIONS' (state, { companions }) {
    state.companions = companions
  },
  'SET_PROVISORY_DATA' (state, { provisoryData }) {
    state.provisoryData = provisoryData
  },
}

export default mutations
