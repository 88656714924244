<template lang="pug">
#FitnessConditions
  h2 ¿Cuantas veces a las semana realizas actividad física?
  .slider
    .sub-slider
      el-slider(v-model="travellerProfile.physicalState", :step="1", :show-stop="true", :max="3", :show-tooltip="false")
  div(style="display: flex; justify-content: space-between; text-align: center;")
    .text-legend(v-for="l in physicalLegend", :style="getStyleLegend(l)")
      label {{ l }}
  h2 Nivel de intensidad del ejercicio:
  .slider
    .sub-slider
      el-slider(v-model="travellerProfile.fitnessIntensity", :step="1", :format-tooltip="formatTooltipFirstSlider", :show-tooltip="false", :show-stop="true", :max="3")
  div(style="display: flex; justify-content: space-between; text-align: center;")
    .text-legend(v-for="l in freqLengend", :style="getStyleLegend(l)")
      label {{ l }}
  br
  .button-group
    el-button#explora-btn.primary(@click="saveDataConditions") GUARDAR
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      physicalLegend: ['0', '1-2', '3-5', '+6'],
      freqLengend: ['Bajo', 'Moderado', 'Intenso', 'Profesional']
    }
  },
  computed: {
    ...mapGetters(['travellerProfile'])
  },
  methods: {
    ...mapActions(['UpdatePhysicalConditions']),
    async saveDataConditions () {
      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      const resp = await this.UpdatePhysicalConditions({
        travellerId: this.travellerProfile.id,
        confirmationNumber: this.$route.params.confirmationNumber,
        fitnessIntensity: this.travellerProfile.fitnessIntensity,
        physicalState: this.travellerProfile.physicalState
      })
      if (resp) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡Error!',
          message: 'Ha sucedido un problema, por favor inténtalo más tarde',
          type: 'error'
        })
      }
    },
    getStyleLegend (legend) {
      if (legend === 'Medio') {
        return { 'margin-right': '8px' }
      }
    },
    formatTooltipFirstSlider (val) {
      switch (val) {
        case 0:
          return 'Caminar menos de 5 minutos al día'
        case 1:
          return 'Caminar menos de 10 minutos al día'
        case 2:
          return 'Transportar pesos livianos o andar en bicicleta a velocidad regular'
        case 3:
          return 'Levantar pesos pesados, ejercicios hacer aeróbicos de más de 30 minutos o andar rápido en bicicleta'
      }
    }
  }
}
</script>
<style lang="scss">
#FitnessConditions {
  padding: 8px 24px;
  text-align: left;
  .text-legend {
    font-size: 12px;
    width: 40px;
  }
  .slider {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .sub-slider {
    width: 90%;
  }
}
</style>
