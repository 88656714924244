<template lang="pug">
#AirportForm
  el-form(ref="form", :model="form", :rules="rules")
    .input-label Aeropuerto
    el-form-item(prop="airPort")
      el-select(v-model="form.airPort", filterable, placeholder="Selecciona aeropuerto", style="width: 100%;")
        el-option(v-for="(a, index) in airPorts", v-bind:key="index", :label="a.value" :value="a.cityCode")
      .message(style="padding: 0;") {{ airPortWarning }}
    .input-label Aerolinea
    el-form-item(prop="airLine")
      el-select(v-model="form.airLine" placeholder="Select")
        el-option(v-for="item in hotel.airlines", :key="item.code", :label="item.name", :value="item.code")
    .input-label Número de vuelo
    el-form-item(prop="flightNumber")
      el-input(v-model="form.flightNumber", type="number")
    .input-label(v-if="type === 'ARRIVAL'") HORA DE VUELO LLEGADA
    .input-label(v-if="type === 'DEPARTURE'") HORA DE VUELO SALIDA
    .date-time-container
      el-form-item(prop="date", style="width: 65%;" )
        el-date-picker(v-model="form.date" format="dd-MM-yyyy" :disabled="form.airPort == ''", :default-value="defaulValue" type="date", placeholder="Selecciona fecha", :picker-options="datePickerOptions")
      el-form-item(prop="time", style="width: 35%;")
        el-time-picker(v-model="form.time" format="HH:mm", :disabled="form.airPort == ''", placeholder="Selecciona hora", v-on:input.native="validateTime")
    .message(v-if="(form.time && isLate) || form.time === ''", v-html="lateFlightAtacama")
    .button-group
      el-button#explora-btn.primary(@click="checkTimeTransport()") Guardar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: ['type', 'legNumber', 'isLast', 'addIndex', 'hotel', 'confirmationNumber'],
  data () {
    return {
      form: {
        airPort: '',
        airLine: '',
        flightNumber: '',
        date: '',
        time: ''
      },
      reservation: null,
      partyApply: [],
      datePickerOptions: '',
      rules: {
        airPort: [{ required: true, message: 'No dejes aeropuerto vacío', trigger: 'blur' }],
        airLine: [{ required: true, message: 'No dejes aerolinea vacía', trigger: 'blur' }],
        flightNumber: [{ required: true, message: 'No dejes número de vuelo vacío', trigger: 'blur' }],
        date: [{ required: true, message: 'No dejes fecha vacía', trigger: 'blur' }],
        time: [{ required: true, message: 'No dejes hora vacía', trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['updateTransport']),
    validateTime (event) {
      if (
        event.target.value &&
        event.target.value.length === 2 &&
        !event.target.value.includes(':') &&
        event.data !== null
      ) {
        event.target.value += ':'
      }
    },
    checkTimeTransport () {
      const flightTime = moment(moment(this.form.date).format('HH:mm'), 'HH:mm')
      let needPrivateTransport = this.availableSchedules.length > 0
      this.availableSchedules.forEach(schedule => {
        const start = moment(schedule.timeStart, 'HH:mm')
        const end = moment(schedule.timeEnd, 'HH:mm')
        if (flightTime.isBetween(start, end, null, '[]')) {
          needPrivateTransport = false
        }
      })
      if (needPrivateTransport) {
        this.$confirm('Horario fuera de rango', 'Horario fuera de rango', {
          confirmButtonText: 'ENTENDIDO',
          cancelButtonText: 'CORREGIR',
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.saveDataAndContinue('form', true)
        }).catch(() => {})
      } else {
        this.saveDataAndContinue('form', false)
      }
    },
    async saveDataAndContinue (form, warning) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const transport = this.transportsInformation.find(t => t.cityCode === this.form.airPort && t.hotel.id === this.hotel.id && t.transportType === 'AEROPUERTO')
          const confirmationNumbers = [this.confirmationNumber]

          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, `${this.travellerProfile.firstName} ${this.travellerProfile.lastName}`))
          this.travellerProfile.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })
          this.travellerProfile.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
                key: res.confirmationNumber
              },
              domProps: { value: reservationCheckVal.value },
              on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          let canContinue = true

          if (parseInt(this.travellerProfile.reservationConsulted.partyCode) === this.confirmationNumber && this.travellerProfile.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: 'Selecciona viajeros',
              message: h('div', null, [
                h('p', null, '¿Quieres asignar tu información de transporte a otros viajeros de tu grupo?'),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }

          if (canContinue) {
            this.$notify({
              title: 'Guardando cambios...',
              message: 'Por favor no cierre el navegador',
              type: 'warning'
            })
            const resp = await this.updateTransport({
              travellerId: this.travellerProfile.id,
              confirmationNumbers: confirmationNumbers,
              transportId: `${this.form.flightNumber}`,
              transportType: transport.transportTypeOws,
              carrierCode: this.form.airLine,
              locationCode: transport.cityCode,
              date: `${moment(this.form.date).format('YYYY-MM-DD')} ${moment(this.form.time).format('HH:mm')}:00`,
              queryType: this.type,
              legNumber: this.legNumber,
              warning: warning,
              destinationType: 'AIRPORT'
            })
            if (resp) {
              this.$notify({
                title: '¡Guardado!',
                message: 'Los datos han sido actualizados exitósamente',
                type: 'success'
              })
            } else {
              this.$notify({
                title: '¡Error!',
                message: 'Ha sucedido un problema, por favor inténtalo más tarde',
                type: 'error'
              })
            }
          }
        } else {
          return false
        }
      })
    }
  },
  computed: {
    ...mapGetters(['transportsInformation', 'travellerProfile']),
    isLate () {
      const hotelMaxTime = this.type === 'ARRIVAL' ? this.hotel.maxTimeIn : this.hotel.maxTimeOut

      const momentTime = moment(moment(this.form.time).format('HH:mm') + ':00', 'HH:mm:ss')
      const momentMaxTime = moment(hotelMaxTime, 'HH:mm:ss')

      return momentTime.isSameOrAfter(momentMaxTime)
    },
    lateFlightAtacama () {
      if (this.type === 'ARRIVAL') {
        return this.hotel.transferMsgEsIn
      } else {
        return this.hotel.transferMsgEsOut
      }
    },
    airPorts () {
      return this.transportsInformation.filter(t => t.transportType.toLowerCase() === 'aeropuerto' && t.hotel.id === this.hotel.id)
    },
    availableSchedules () {
      const airport = this.transportsInformation.find(t => t.cityCode === this.form.airPort)
      if (airport) {
        const transportType = this.type === 'ARRIVAL' ? 1 : 2
        return airport.transportSchedule.filter(ts => ts.scheduleType === transportType)
      }
      return []
    },
    airPortWarning () {
      if (this.availableSchedules.length > 0) {
        return (this.availableSchedules.length === 1 ? 'Horario de recogida de la van' : 'Horarios de recogida de la van') + ': ' + this.availableSchedules.map(ts => `${ts.timePickup.substring(0, 5)}`).join(', ')
      }
      return ''
    },
    defaulValue () {
      if (this.type === 'ARRIVAL') return new Date(this.travellerProfile.reservationConsulted.checkin)
      else return new Date(this.travellerProfile.reservationConsulted.checkout)
    }
  },
  created () {
    if (this.legNumber === this.travellerProfile.reservationConsulted.legNumber && this.confirmationNumber === this.travellerProfile.reservationConsulted.confirmationNumber) {
      this.reservation = this.travellerProfile.reservationConsulted
    } else {
      this.reservation = this.travellerProfile.aditionalReservations.find(r => r.legNumber === this.legNumber && r.confirmationNumber === this.confirmationNumber)
    }
    if (this.type === 'ARRIVAL') {
      if (this.reservation.arrivalDestinationType === 'AIRPORT') {
        this.form.airPort = this.reservation.arrivalTransportLocation
        this.form.airLine = this.reservation.arrivalTransportCarrier
        this.form.flightNumber = this.reservation.arrivalTransportId
        this.form.date = this.reservation.arrivalTransportDatetime
      }
    } else {
      if (this.reservation.departureDestinationType === 'AIRPORT') {
        this.form.airPort = this.reservation.departureTransportLocation
        this.form.airLine = this.reservation.departureTransportCarrier
        this.form.flightNumber = this.reservation.departureTransportId
        this.form.date = this.reservation.departureTransportDatetime
      }
    }
    const self = this
    this.datePickerOptions = {
      disabledDate (date) {
        if (self.type === 'ARRIVAL') {
          const dateCheckinTomorrow = new Date(self.reservation.checkin)
          dateCheckinTomorrow.setDate(dateCheckinTomorrow.getDate() + 1)
          const dateCheckin = new Date(self.reservation.checkin)
          dateCheckin.setDate(dateCheckin.getDate() - 1)
          return date > dateCheckinTomorrow || date < dateCheckin
        } else {
          const dateCheckoutTomorrow = new Date(self.reservation.checkout)
          dateCheckoutTomorrow.setDate(dateCheckoutTomorrow.getDate() + 1)
          const dateCheckout = new Date(self.reservation.checkout)
          dateCheckout.setDate(dateCheckout.getDate() - 1)
          return date > dateCheckoutTomorrow || date < dateCheckout
        }
      }
    }
  }
}
</script>
<style lang="scss">
#AirportForm {
  .input-label {
    font-size: 12px;
    margin: 16px 0;
  }
  .title {
    display: flex;
  }
  .el-button--text {
    display: none;
  }
  .el-select {
    width: 100%;
  }
  .date-time-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
