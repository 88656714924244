<template lang="pug">
#Mapi(v-if="travellerProfile")
  el-form.form(ref="form", :model="form", :rules="rules")

    .choose-date(v-html="text.DATE_INFO")

    .input-label.mb-2 Fecha de preferencia
    el-form-item(prop="mapiDate")
      el-date-picker(
        v-model="form.mapiDate"
        format="dd-MM-yyyy"
        type="date"
        :picker-options="datePickerOptions"
        :default-value="defaultChosenDate"
      )

    //- el-form-item(prop="confirmDate")
    //-   el-checkbox(
    //-     v-model="form.confirmDate"
    //-     ref="termsCheckedCovid"
    //-     type="checkbox" id="terms"
    //-   )
    //-     span(v-html="text.DATE_CONFIRM")

    .button-group
      el-button#explora-btn.primary(@click="savePreferredDate") GUARDAR

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import DatePicker from '../Utils/DatePicker.vue'

export default {
  components: { DatePicker },
  data() {
    return {
      text: {},
      form: {
        mapiDate: null,
        confirmDate: false,
      },
      rules: {
      },
    }
  },
  computed: {
    ...mapGetters([
      'nationalities',
      'travellerProfile',
      'confirmationNumberToken',
      'confirmationNumber',
    ]),

    reservation() {
      return this.travellerProfile.reservationConsulted
    },

    defaultChosenDate() {
      return moment(this.reservation.checkout)
        .startOf('day')
        .subtract(1, 'days')
    },

    datePickerOptions() {
      return { disabledDate: this.disabledPickerDate }
    },
  },
  methods: {
    ...mapActions([
      'updateMapiPreferredDate',
      'modifyTravellerStepStatus',
      'updateTravellerDataMapi',
      'getMapiMessages',
    ]),

    isLetter(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\s]*$/.test(char)) return true
      else e.preventDefault()
    },

    disabledPickerDate(date) {
      const { checkin, checkout } = this.reservation
      return !moment(date).isBetween(checkin, checkout, 'day', '()')
    },

    async savePreferredDate() {
      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      const res = await this.updateMapiPreferredDate({
        confirmationNumber: this.reservation.confirmationNumber,
        legNumber: this.reservation.legNumber,
        mapiPreferredDate: new Date(this.form.mapiDate)
          .toISOString()
          .split('T')[0],
      })
      if (res) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡Error!',
          message: 'Ha sucedido un problema, por favor inténtalo más tarde',
          type: 'error'
        })
      }
    },
  },

  watch: {
    defaultChosenDate: {
      handler(val) {
        this.form.mapiDate = this.defaultChosenDate
      },
      immediate: true,
    },
  },

  async created() {
    this.form.name = this.travellerProfile.firstName
    this.form.lastName = this.travellerProfile.lastName
    this.form.birthdate =
      this.travellerProfile.birthday === null
        ? '2020-01-01'
        : this.travellerProfile.birthday
    this.form.nationality = this.travellerProfile.nationality
    this.form.document = this.travellerProfile.dni
    this.form.mapiDate = moment(this.reservation.mapiVisit?.preferredDate)
    this.text = await this.getMapiMessages()
  },
}
</script>

<style lang="scss" scoped>
.warning {
  width: 100%;
  border: 1px solid #e6a23c;
  padding: 10px;
}

.choose-date {
  @extend .warning;
  margin-top: 20px;
  margin-bottom: 30px;
  border-color: #409eff;
}
</style>
