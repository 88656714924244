<template lang='pug'>
#app
  Home(v-if="user")
  router-view(:key="$route.path")
</template>
<script>
import { mapGetters } from 'vuex'
import Home from './pages/Home.vue'
import lang from 'element-ui/lib/locale/lang/es'
import locale from 'element-ui/lib/locale'

locale.use(lang)

export default {
  components: {
    Home
  },
  created () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.$store.commit('SET_USER', { user: user })
    }
    this.checkSession()
  },
  methods: {
    checkSession () {
      if (this.$route.meta.routeType === 'public') {
        if (this.user) {
          this.$router.push('/reservations/agentReservations')
        }
      } else {
        if (!this.user) {
          this.$router.push('/')
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    '$route' () {
      this.checkSession()
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/utility.scss';

body {
  margin: 0 !important;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
