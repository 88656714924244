<template lang="pug">
#Logs
  el-card
    //- el-button(type="primary", @click="goBack") Atrás
    .logs(v-html="logs")
</template>
<script>
import gql from 'graphql-tag'

export default {
  data () {
    return {
      logs: ''
    }
  },
  methods: {
    goBack () {
      this.$router.push('/reservations/agentReservations')
    }
  },
  async created () {
    const resp = await this.$apollo.mutate({
      mutation: gql`query {
        logTravellerProfile(idLog: ${this.$route.params.idLogs})
      }`,
      variables: null
    })
    this.logs = resp.data.logTravellerProfile
  }
}
</script>
<style lang="scss">
#Logs {
  text-align: left;
}
</style>
