<template lang="pug">
#Basic(v-if="travellerProfile")
  el-form.form(ref="form", :model="form", :rules="rules")
    h2 INFORMACIÓN BÁSICA
    // Datos base
    .input-label NOMBRE
     .red *
    el-form-item(prop="name")
      el-input(v-model="form.name", ref="name", v-on:keypress.native="isLetter($event)")
    .input-label APELLIDO
      .red *
    el-form-item(prop="lastName")
      el-input(v-model="form.lastName", ref="lastName",  v-on:keypress.native="isLetter($event)")
    .input-label GÉNERO
    el-form-item(prop="gender")
      el-select(v-model="form.gender", placeholder="Selecciona género", style="width: 100%;",  ref="gender")
        el-option(label="MASCULINO" value="MALE")
        el-option(label="FEMENINO" value="FEMALE")
        el-option(label="PREFIERO NO DECIRLO" value="UNKNOWN")
    .input-label FECHA DE NACIMIENTO
      .red *
    el-form-item(prop="gender")
      DatePicker(v-model="form.birthdate", ref="birthdate")
    .input-label NACIONALIDAD
      .red *
    el-form-item(prop="nationality")
      el-select(v-model="form.nationality", filterable, placeholder="Selecciona nacionalidad", style="width: 100%;")
        el-option(v-for="(n, index) in nationalities", v-bind:key="index", :label="n.description" :value="n.value")
    .special-form
      div(style="width: 45%")
        .input-label TIPO DE DOCUMENTO
        el-input(v-model="form.docType", disabled)
      div(style="width: 45%")
        .input-label NÚMERO DE DOCUMENTO
          .red *
        el-form-item(prop="document")
          el-input(v-model="form.document", ref="document")
    // Direccion
    h2 LUGAR DE RESIDENCIA
    .input-label PAÍS
    el-form-item(prop="country")
      el-select(v-model="form.country", filterable, placeholder="Selecciona país", style="width: 100%;")
        el-option(v-for="(c, index) in countries", v-bind:key="index", :label="c.description" :value="c.value")
    div(v-if="states.length > 0")
      .input-label ESTADO
      el-form-item(prop="state")
        el-select(v-model="form.state", filterable, placeholder="Selecciona estado", style="width: 100%;")
          el-option(v-for="(s, index) in states", v-bind:key="index", :label="s.name" :value="s.state_code")
    .input-label CIUDAD
    el-form-item(prop="city")
      el-select(v-model="form.city", filterable, ref="city", style="width: 100%;")
        el-option(v-for="(c, index) in cities", v-bind:key="index", :label="c.name" :value="c.name")
    .input-label DIRECCIÓN
    el-form-item(prop="address")
      el-input(v-model="form.address", ref="address")
    // Contacto
    h2 DATOS DE CONTACTO
    .input-label EMAIL
    el-form-item(prop="email")
      el-input(v-model="form.email", ref="email")
    .input-label TELÉFONO MOVIL
    el-form-item(prop="phoneNumber")
      el-input(v-model="inpNum", type="text", :max_length="11", ref="phoneNumber")
    .button-group
      el-button#explora-btn.primary(@click="saveDataAndContinue('form')") GUARDAR
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '../Utils/DatePicker.vue'
import locations from '../../../plugins/locations.json'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      activeName: '1',
      loadingStates: false,
      states: [],
      cities: [],
      ready: false,
      form: {
        travellerId: null,
        name: '',
        lastName: '',
        gender: '',
        birthdate: '',
        nationality: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        address: '',
        docType: 'DNI/Pasaporte',
        document: '',
        email: '',
        phoneNumber: '',
        emailEmergency: '',
        phoneNumberEmergency: '',
        operaIdAddress: '',
        operaIdEmail: '',
        operaIdEmailEmergency: '',
        operaIdPhoneNumber: '',
        operaIdphoneNumberEmergency: ''
      },
      rules: {
        name: [{ required: true, message: 'No dejes nombre vacío', trigger: 'blur' }],
        lastName: [{ required: true, message: 'No dejes apellido vacío', trigger: 'blur' }],
        document: [{ required: true, message: 'No dejes documento vacío', trigger: 'blur' }],
        birthdate: [{ required: true, message: 'No dejes fecha de nacimiento vacía', trigger: 'blur' }],
        nationality: [{ required: true, message: 'Ingresa una nacionalidad', trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['saveBasicInformationData']),
    validateFocus () {
      if (this.$refs.name.validateState === 'error') {
        this.$refs.name.focus()
        return false
      }
      if (this.$refs.lastName.validateState === 'error') {
        this.$refs.lastName.focus()
        return false
      }
      if (this.$refs.document.validateState === 'error') {
        this.$refs.document.focus()
        return false
      }
      if (this.$refs.birthdate.validateState === 'error') {
        this.$refs.birthdate.focus()
        return false
      }
      if (this.$refs.nationality.validateState === 'error') {
        this.$refs.birthdate.focus()
        return false
      }
    },
    validate (form) {
      this.$refs[form].validate(() => {})
    },
    isLetter (e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\s]*$/.test(char)) return true
      else e.preventDefault()
    },
    async saveDataAndContinue (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          await this.saveTravellerProfile()
        } else {
          this.validateFocus()
          return false
        }
      })
    },
    async saveTravellerProfile () {
      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      const resp = await this.saveBasicInformationData({
        id: this.form.travellerId,
        dni: this.form.document,
        name: this.form.name,
        lastName: this.form.lastName,
        gender: this.form.gender,
        birthdate: this.form.birthdate,
        nationality: this.form.nationality,
        country: this.form.country,
        state: this.form.state,
        city: this.form.city,
        // zipCode: this.form.zipCode,
        address: this.form.address,
        email: this.form.email,
        emailEmergency: this.form.emailEmergency,
        phoneNumber: this.form.phoneNumber,
        phoneNumberEmergency: this.form.phoneNumberEmergency,
        operaIdEmail: this.form.operaIdEmail,
        operaIdAddress: this.form.operaIdAddress,
        operaIdPhoneNumber: this.form.operaIdPhoneNumber,
        operaIdPhoneNumberEmergency: this.form.operaIdphoneNumberEmergency,
        operaIdEmailEmergency: this.form.operaIdEmailEmergency,
        confirmationNumber: this.$route.params.confirmationNumber
      })
      if (resp) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡Error!',
          message: 'Ha sucedido un problema, por favor inténtalo más tarde',
          type: 'error'
        })
      }
    }
  },
  computed: {
    ...mapGetters(['nationalities', 'countries', 'travellerProfile']),
    thisCountry () {
      return this.form.country
    },
    inpNum: {
      get: function () {
        return this.form.phoneNumber
      },
      set: function (newValue) {
        this.form.phoneNumber = newValue.replace(/[^\d]/g, '')
      }
    },
    inpNumEmergency: {
      get: function () {
        return this.form.phoneNumberEmergency
      },
      set: function (newValue) {
        this.form.phoneNumberEmergency = newValue.replace(/[^\d]/g, '')
      }
    }
  },
  async created () {
    this.form.travellerId = this.travellerProfile.id
    this.form.name = this.travellerProfile.firstName
    this.form.lastName = this.travellerProfile.lastName
    if (this.travellerProfile.gender === 'M') {
      this.form.gender = 'MALE'
    } else if (this.travellerProfile.gender === 'F') {
      this.form.gender = 'FEMALE'
    } else {
      this.form.gender = 'UNKNOWN'
    }
    this.ready = false
    // this.form.birthdate = this.travellerProfile.birthday === null ? '2020-01-01' : this.travellerProfile.birthday
    this.form.birthdate = ''
    this.form.nationality = this.travellerProfile.nationality
    this.form.country = this.travellerProfile.address ? this.travellerProfile.address.country : ''
    this.form.state = this.travellerProfile.address ? this.travellerProfile.address.region : ''
    this.form.city = this.travellerProfile.address ? this.travellerProfile.address.city : ''
    this.form.operaIdAddress = this.travellerProfile.address ? this.travellerProfile.address.operaId : ''
    this.form.zipCode = this.travellerProfile.address ? this.travellerProfile.address.zipCode : ''
    this.form.address = this.travellerProfile.address ? this.travellerProfile.address.address : ''
    this.form.document = this.travellerProfile.dni
    const email = this.travellerProfile.contacts.find(c => c.contactRole === 'EMAIL' && c.contactType === 'EMAIL')
    const emailEmergency = this.travellerProfile.contacts.find(c => c.contactRole === 'EMAIL' && c.contactType === 'EMRG_EMAIL')
    const phoneNumber = this.travellerProfile.contacts.find(c => c.contactRole === 'PHONE' && c.contactType === 'MOBILE')
    const phoneNumberEmergency = this.travellerProfile.contacts.find(c => c.contactRole === 'PHONE' && c.contactType === 'EMERGENCY')
    this.form.email = email ? email.value : ''
    this.form.emailEmergency = emailEmergency ? emailEmergency.value : ''
    this.form.phoneNumber = phoneNumber ? phoneNumber.value : ''
    this.form.phoneNumberEmergency = phoneNumberEmergency ? phoneNumberEmergency.value : ''
    this.form.operaIdEmail = email ? email.operaId : 'None'
    this.form.operaIdEmailEmergency = emailEmergency ? emailEmergency.operaId : 'None'
    this.form.operaIdPhoneNumber = phoneNumber ? phoneNumber.operaId : 'None'
    this.form.operaIdphoneNumberEmergency = phoneNumberEmergency ? phoneNumberEmergency.operaId : 'None'
    setTimeout(() => { this.ready = true }, 1)
  },
  watch: {
    'form.country': function () {
      const country = locations.find((lc) => lc.iso2 === this.form.country)
      if (country) {
        this.states = country.states
      }
      if (this.ready) this.form.state = ''
      if (this.ready) this.form.city = ''
    },
    'form.state': function () {
      if (this.form.state) {
        const stateObj = this.states.find((st) => st.state_code === this.form.state)
        if (stateObj) {
          this.cities = stateObj.cities
        }
      }
      if (this.ready) this.form.city = ''
    }
  }
}
</script>
<style lang="scss">
#Basic {
  .form {
    padding: 8px 16px;
    text-align: left;
    .input-label {
      font-size: 12px;
      margin: 16px 0;
      display: flex;
      .red {
        color: red;
        margin-left: 2px;
      }
    }
  }
  .special-form {
    display: flex;
    justify-content: space-between;
  }
  p {
    text-align: justify;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(6, 7, 8);

    animation: spin 1s ease infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
