import mutations from './mutations'
import * as getters from './getters'
import * as actions from './actions'

const SimilarTraverlerStore = {
  state: {
    allSimilarTravelers: [],
    similarTravelerDetail: null
  },
  actions,
  getters,
  mutations
}

export default SimilarTraverlerStore
