<template lang="pug">
#Transport
  .hotel-select(v-if="hotels.length > 0")
    el-button#explora-btn.border(v-for="(hotel, ind) in hotels", :class="ind === index ? 'primary' : 'secondary'", @click="activateIndex(ind)")
      div(style="display: flex; align-items: center;")
        label {{ hotel.name }}
        .circle(v-if="isCompleted(legNumbers[ind])")
          .el-icon-check
  div
    #Transport(style="margin-top: 16px;", v-if="(reservations[index].packagesCodes.filter(pkc => ['3NBMCH', '4NBMCH'].includes(pkc)).length > 0 && type === 'ARRIVAL') || (reservations[index].packagesCodes.filter(pkc => ['3NCHBM', '4NCHBM'].includes(pkc)).length > 0 && type === 'DEPARTURE')")
      PNP-msg(:transportType="type", :reservation="reservations[index]")
      .button-group(style="margin-top: 16px;")
        el-button#explora-btn.primary(@click="goNext(legNumbers[index], index + 1 === reservations.length)", :disabled="checkinsTime[index] === '' || checkinsTime[index] === null") Guardar
    #Transport(style="margin-top: 16px;", v-else-if="reservations[index].packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH', '3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0 && type === 'ARRIVAL'")
      connect-msg(:reservation="reservations[index]")
      .button-group(style="margin-top: 16px;")
        el-button#explora-btn.primary(@click="goNext(legNumbers[index], index + 1 === reservations.length)", :disabled="checkinsTime[index] === '' || checkinsTime[index] === null") Guardar
    #Transport(v-else)
      div
        h1(v-if="type === 'ARRIVAL'") CHECKIN
        h1(v-if="type === 'DEPARTURE'") CHECKOUT
        .form-radio
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC'", v-model="itemActive[index]", label="airport") Aeropuerto
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC'", v-model="itemActive[index]", label="hotel") Hotel
          el-radio.custom-radio(:disabled="reservations[index].travellerType === 'BASIC'", v-model="itemActive[index]", label="dontNeed") No necesita transporte
      .forms
        airport-form(v-if="itemActive[index] === 'airport'", :confirmationNumber="reservations[index].confirmationNumber" ,:type="type", :hotel="hotels[index]", :legNumber="legNumbers[index]", :isLast="index + 1 === reservations.length", :addIndex="addIndex")
        hotel-form(v-if="itemActive[index] === 'hotel'", :confirmationNumber="reservations[index].confirmationNumber", :type="type", :hotel="hotels[index]", :legNumber="legNumbers[index]", :isLast="index + 1 === reservations.length", :addIndex="addIndex", :dateTransport="type === 'ARRIVAL' ? reservations[index].checkin : reservations[index].checkout")
      div(style="margin-top: 16px;", v-if="itemActive[index] === 'dontNeed' || reservations[index].travellerType === 'BASIC'")
        .message(v-if="reservations[index].travellerType === 'BASIC'") El programa Experience no incluye traslados de llegada y salida a nuestro hotel, si aun no tienes resuelto como llegar a tu destino Explora, contáctate con nuestra área de reserva para ver una alternativa con nosotros.
        .message(v-if="reservations[index].hotel.id === '6' && this.type === 'ARRIVAL'", v-html="htmlChalten")
        .input-label(v-if="this.type === 'ARRIVAL'") Confirma hora de check-in:
        el-time-picker(v-model="checkinsTime[index]", v-if="this.type === 'ARRIVAL'", arrow-control, format="HH:mm", placeholder="Select time", v-on:input.native="validateTime")
        el-switch(v-model="arrivesLunch[index]", v-if="this.type === 'ARRIVAL' && isLunchTime(checkinsTime[index])", active-text="Llega a almorzar", inactive-text="No llega a almorzar")
        .button-group(style="margin-top: 16px;")
          el-button#explora-btn.primary(@click="SaveDontNeed(legNumbers[index], index + 1 === reservations.length)", :disabled="checkinsTime[index] === '' || checkinsTime[index] === null") Guardar
</template>
<script>
import AirportForm from './TransportForm/AirportForm.vue'
import HotelForm from './TransportForm/HotelForm.vue'
import { mapGetters, mapActions } from 'vuex'
import PNPMsg from './PNPMsg.vue'
import ConnectMsg from './ConnectMsg.vue'
import moment from 'moment'

export default {
  props: ['type'],
  data () {
    return {
      itemActive: [],
      reservations: [],
      legNumbers: [],
      hotels: [],
      index: 0,
      checkinsTime: [],
      arrivesLunch: [],
      htmlChalten: "Antes de tu viaje comunícate con <a href='mailto:recechalten@explora.com' target='_blank'>recechalten@explora.com</a> o whatsapp <a href='https://wa.me/5492966403225' target='_blank'>+54 9 2966 40 3225</a> para entregarte la clave de acceso a la reserva privada donde está ubicado nuestro lodge."
    }
  },
  components: {
    AirportForm,
    HotelForm,
    ConnectMsg,
    PNPMsg
  },
  computed: {
    ...mapGetters(['travellerProfile'])
  },
  methods: {
    ...mapActions(['CancellTransport']),
    isLunchTime (time) {
      const htime = moment(time).format('HH')
      const mtime = moment(time).format('mm')

      if (htime && mtime) {
        if (parseInt(htime) === 13) return true
        if (parseInt(htime) === 14 && parseInt(mtime) === 0) return true
      }

      return false
    },
    validateTime (event) {
      if (
        event.target.value &&
        event.target.value.length === 2 &&
        !event.target.value.includes(':') &&
        event.data !== null
      ) {
        event.target.value += ':'
      }
    },
    showForm (form) {
      this.itemActive = form
    },
    goBack () {
      this.itemActive = ''
    },
    activateIndex (ind) {
      this.index = ind
    },
    async goNext (legNumber, isLast) {
      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      if (!isLast) {
        this.index += 1
      }
    },
    addIndex () {
      this.index += 1
    },
    isCompleted (legNumber) {
      const step = this.type === 'ARRIVAL' ? 2 : 3
      const stepHotel = this.travellerProfile.reservationConsulted.steps.filter(st => st.step === step && st.legNumber === legNumber)
      if (stepHotel.length > 0) {
        return stepHotel[0].status === 'OK'
      } else {
        return false
      }
    },
    async SaveDontNeed (legNumber, isLast) {
      const checkinTime = moment(this.checkinsTime[this.index]).format('HH:mm')

      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      if (!isLast) {
        this.index += 1
      }
      const resp = await this.CancellTransport({
        travellerId: this.travellerProfile.id,
        queryType: this.type,
        confirmationNumber: this.reservations[this.index].confirmationNumber,
        legNumber: legNumber,
        checkin: checkinTime,
        arrivesToLunch: this.arrivesLunch[this.index] && this.isLunchTime(this.checkinsTime[this.index]) ? this.arrivesLunch[this.index] : false
      })
      if (resp) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡Error!',
          message: 'Ha sucedido un problema, por favor inténtalo más tarde',
          type: 'error'
        })
      }
    }
  },
  created () {
    if (this.travellerProfile.reservationConsulted.crossingGroup) {
      if (this.type === 'ARRIVAL') {
        this.reservations.push(this.travellerProfile.reservationConsulted)
      } else {
        this.travellerProfile.aditionalReservations.forEach(reservation => {
          this.reservations.push(reservation)
        })
      }
    } else if (this.travellerProfile.reservationConsulted.exploraConnectId) {
      if (this.type === 'ARRIVAL') {
        this.reservations.push(this.travellerProfile.reservationConsulted)
        this.travellerProfile.aditionalReservations.forEach(reservation => { this.reservations.push(reservation) })
      } else {
        this.travellerProfile.aditionalReservations.forEach(reservation => { this.reservations.push(reservation) })
        // this.reservations.push(this.travellerProfile.reservationConsulted)
      }
    } else {
      this.reservations.push(this.travellerProfile.reservationConsulted)
      this.travellerProfile.aditionalReservations.forEach(reservation => {
        this.reservations.push(reservation)
      })
    }
    let inx = 0
    this.reservations.forEach(reservation => {
      this.legNumbers[inx] = reservation.legNumber
      this.hotels[inx] = reservation.hotel
      this.checkinsTime[inx] = reservation.checkin
      if (reservation.specialTransportCrossing) {
        this.hotels[inx] = { ...this.travellerProfile.reservationConsulted.hotel }
        this.hotels[inx].name = 'Uyuni / Calama'
      }
      if (reservation.travellerType !== 'BASIC') {
        if (this.type === 'ARRIVAL') {
          if (reservation.arrivalStatus === 'CANCELLED') {
            this.itemActive[inx] = 'dontNeed'
          } else if (
            reservation.arrivalDestinationType &&
            reservation.arrivalDestinationType === 'AIRPORT'
          ) {
            this.itemActive[inx] = 'airport'
          } else if (
            reservation.arrivalDestinationType &&
            reservation.arrivalDestinationType === 'HOTEL'
          ) {
            this.itemActive[inx] = 'hotel'
          }
        } else if (this.type === 'DEPARTURE') {
          if (reservation.departureStatus === 'CANCELLED') {
            this.itemActive[inx] = 'dontNeed'
          } else if (
            reservation.departureDestinationType &&
            reservation.departureDestinationType === 'AIRPORT'
          ) {
            this.itemActive[inx] = 'airport'
          } else if (
            reservation.departureDestinationType &&
            reservation.departureDestinationType === 'HOTEL'
          ) {
            this.itemActive[inx] = 'hotel'
          }
        }
      }
      inx += 1
    })
  }
}
</script>
<style lang="scss">
#Transport {
  padding: 8px 24px;
  text-align: left;
  .boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .form-radio {
    display: grid;
  }
  .custom-radio {
    margin-bottom: 16px;
  }
  .button-group-one {
    display: flex;
    padding-left: 25px;
    margin: 20px 0;
  }
  .border {
    border-radius: 5px;
    border: black 0.5px solid;
    padding: 8px;
  }
  .circle {
    width: 12px;
    height: 12px;
    font-size: 10px;
    border: 1px black solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f99921;
    color: white;
    margin-left: 8px;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
  .message {
    font-size: 13px;
    font-style: italic;
    padding: 30px 0px;
  }
}
.el-switch__label.is-active {
  color: #121212 !important;
  font-weight: bold !important;
}
.el-switch.is-checked .el-switch__core {
  border-color: #121212 !important;
  background-color: #121212 !important;
}
.el-switch {
  margin-top: 16px;
}
</style>
