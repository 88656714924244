<template lang="pug">
#BlockedMsg
  //- Radio
  el-radio(v-if="reservation.packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH'].includes(pkc)).length > 0") Aeródromo de Cochrane
  el-radio(v-else-if="reservation.packagesCodes.filter(pkc => ['3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0") Aeropuerto de Puerto Natales
  //- Mensajes
  .pnp-msg(v-if="reservation.packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH'].includes(pkc)).length > 0") {{ tdpTransfer }}
  .pnp-msg(v-else-if="reservation.packagesCodes.filter(pkc => ['3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0") {{ pnpTransfer }}
</template>
<script>
import moment from 'moment'

export default {
  props: ['reservation'],
  data () {
    return {
      connectPnp: 'Usted tiene un vuelo programado el día <DATE> a las en horario AM por confirmar hrs desde el aeropuerto de Puerto Natales. Nuestro equipo EXPLORA los dejará en manos de equipo DAP para guiarlos en su embarque desde ese punto.',
      connectTdp: 'Usted tiene un vuelo programado el dia <DATE> en horario por confirmar desde el aerodromo de Cochrane. Nuestro equipo EXPLORA los dejará en la puerta del avion.'
    }
  },
  computed: {
    pnpTransfer () {
      return this.connectPnp.replace('<DATE>', moment(this.reservation.checkin).format('DD/MM/YYYY'))
    },
    tdpTransfer () {
      return this.connectTdp.replace('<DATE>', moment(this.reservation.checkin).format('DD/MM/YYYY'))
    }
  }
}
</script>
<style lang="scss">
#BlockedMsg {
  text-align: left;
  padding: 22px;
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
}
</style>
