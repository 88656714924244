import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const TravellerProfileStore = {
  state: {
    travellerProfile: null,
    nationalities: [],
    countries: [],
    transportsInformation: [],
    tagsConditions: [],
    interests: [],
    companions: [],
    provisoryData: [],
  },
  getters,
  mutations,
  actions
}

export default TravellerProfileStore
