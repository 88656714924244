<template lang="pug">
#TravellerProfile
  div(v-if="travellerProfile")
    h2 Perfil de {{ travellerName }}
    .flex-row.justify-center.align-center.gap-5
      .avatar
        img(v-if="travellerProfile.avatarUrl" :src="travellerProfile.avatarUrl")
        .big-letter(v-else) {{ travellerName[0] }}
      .header-info
        h4.header Conf. number: {{$route.params.confirmationNumber}}
        h4.header Party: {{travellerProfile.reservationConsulted.partyCode}}
        h4.header Hotel: {{travellerProfile.reservationConsulted.hotel.name}}
        h4.header Checkin: {{travellerProfile.reservationConsulted.checkin | formatedDate}}
        h4.header Checkout: {{checkout | formatedDate}}
    companions
    .cards
      el-card.full
        basic-information
      el-card.half
        transport(type="ARRIVAL")
      el-card.half
        transport(type="DEPARTURE")
      el-card.half
        traveller-requirements
      el-card.full(v-if="travellerProfile.reservationConsulted.includesMapi")
        mapi-form
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Loading } from 'element-ui'
import Companions from '@/components/TravellerProfile/Companions.vue'
import BasicInformation from '@/components/TravellerProfile/BasicInformation.vue'
import Transport from '@/components/TravellerProfile/Transport.vue'
import TravellerRequirements from '@/components/TravellerProfile/TravellerRequirements.vue'
import FitnessConditions from '@/components/TravellerProfile/FitnessConditions.vue'
import TravellerInterests from '@/components/TravellerProfile/TravellerInterests.vue'
import MapiForm from '@/components/TravellerProfile/MapiForm.vue'

export default {
  components: {
    Companions,
    BasicInformation,
    Transport,
    TravellerRequirements,
    FitnessConditions,
    TravellerInterests,
    MapiForm,
  },
  methods: {
    ...mapActions(['getProfile', 'getFormInformation', 'getTransportInformation', 'getTags', 'getInterests']),
    async getTravellerProfile () {
      await this.getProfile({ token: this.$route.params.profileToken, confirmationNumber: this.$route.params.confirmationNumber })
    }
  },
  computed: {
    ...mapGetters(['travellerProfile']),
    travellerName () {
      if (this.travellerProfile) return `${this.travellerProfile.firstName} ${this.travellerProfile.lastName}`
      return ''
    },
    checkout () {
      if (this.travellerProfile.aditionalReservations.length > 0) {
        return this.travellerProfile.aditionalReservations[this.travellerProfile.aditionalReservations.length - 1].checkout
      } else {
        return this.travellerProfile.reservationConsulted.checkout
      }
    }
  },
  async created () {
    const loading = Loading.service()
    this.$store.commit('SET_TRAVELLER_PROFILE', { travellerProfile: null })
    await this.getFormInformation()
    await this.getInterests({ confirmationNumber: this.$route.params.confirmationNumber })
    await this.getTransportInformation({ confirmationNumber: this.$route.params.confirmationNumber })
    await this.getTags()
    await this.getTravellerProfile()
    loading.close()
  }
}
</script>
<style lang="scss">
#TravellerProfile {
  padding-top: 120px;
  padding-left: 90px;
  text-align: left;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .full, .half {
    margin: 4px;
  }
  .half {
    width: 49%;
  }
  .full {
    width: 100%;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
  .header {
    margin: 0;
  }
  .avatar {
    width: 85px;
    height: 85px;
    background: aliceblue;
    border: 1px solid #80808059;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .header-info {
    text-align: left;
  }
}
#explora-btn {
    border-radius: 25px;
    &.primary {
        background-color: #303133;
        color: white;
    }
    &.secondary {
        color: #303133;
        border: 0px;
    }
}

.el-message-box__btns button:nth-child(2) {
    background-color: #303133;
    border-color: #303133;
    color: white;
    border-radius: 25px;
}
.el-button--small, .el-button--small.is-round {
    color: #303133;
    border: 0px !important;
}

.el-select-dropdown__item {
    font-family: sans-serif;
}

.el-picker-panel__body {
    font-family: sans-serif;
}
.blocked-msg {
    font-style: italic;
}
.pnp-msg {
  font-style: italic;
  margin-top: 22px;
  border: 1px solid;
  padding: 16px;
}
h1, h2 {
  font-size: 25px;
}
.el-checkbox__input.is-checked+.el-checkbox__label, .el-radio__input.is-checked+.el-radio__label {
  color: #606266 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-radio__input.is-checked .el-radio__inner {
  background-color: #606266 !important;
  border-color: #606266 !important;
}
.el-slider__bar {
  background-color: #606266 !important;
}
.el-slider__button {
  border: 2px solid #606266 !important;
}
</style>
