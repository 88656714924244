<template lang="pug">
#HomePage
  .nav-header
    .menu-container
      .menu-collapser(@click="isCollapse = !isCollapse")
        .el-icon-s-operation(v-if="isCollapse")
        .times(v-else) x
      .page-title Back Office
    .close-sesion-div
      el-dropdown.close-sesion
        span.el-dropdown-link {{ user.userName }}
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item(@click.native="logOut()") Cerrar sesión
  .nav-menu
    div(style="margin-right: 30px;")
      el-menu(background-color="#1b1b1b", text-color="#c0c4cc" active-text-color="white" :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse")
        el-menu-item(index='/reservations/agentReservations', @click="goTo('agentReservations')")
          i.el-icon-date
          span(slot='title') Mis reservas
        el-menu-item(index='/reservations/allFutureReservations', @click="goTo('allFutureReservations')")
          i.el-icon-notebook-1
          span(slot='title') Todas las reservas
        el-menu-item(index='/reservations/failedReservations',@click="goTo('failedReservations')")
          i.el-icon-close
          span(slot='title') Guardado fallido (Opera)
        el-menu-item(index='/reservations/failedReservationsPgo',@click="goTo('failedReservationsPgo')")
          i.el-icon-s-release
          span(slot='title') Guardado inconcluso
        el-menu-item(index='/logs', @click="$router.push('/logs')")
          i.el-icon-takeaway-box
          span(slot='title') Logs
        el-menu-item(index='/similar-travellers', @click="$router.push('/similar-travellers')")
          i.el-icon-document-checked
          span(slot='title') Similitudes
        el-menu-item(index='/traveller-metrics', @click="$router.push('/traveller-metrics')")
          i.el-icon-data-analysis
          span(slot='title') Métricas
        el-menu-item(index='/mapi-reservations', @click="$router.push('/mapi-reservations')")
          i.el-icon-map-location
          span(slot='title') MAPI
</template>
<script>
import { mapGetters } from 'vuex'
import ReservationsDataTable from '../components/ReservationsDataTable.vue'
import TravellerMetrics from './TravellerMetrics.vue'
import SimilarTravelersDataTable from './SimilarTravelersDataTable.vue'
import Logs from './LogsPage.vue'
import gql from 'graphql-tag'

export default {
  name: 'home-page',
  data () {
    return {
      allReservationsData: null,
      reservationFailData: null,
      isCollapse: true,
      activeIndex: this.$route.path,
      hotelList: [],
      loading: false
    }
  },
  components: {
    ReservationsDataTable,
    Logs,
    TravellerMetrics,
    SimilarTravelersDataTable
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    logOut () {
      this.$store.commit('SET_USER', { user: null })
      this.$router.push('/')
    },
    goTo (route) {
      this.$router.push('/reservations/' + route).catch(() => {})
    },
    getHotels () {
      this.$apollo.mutate({
        mutation: gql`
          query {
            allHotelsList {
              id
              name
            }
          }
        `,
        variables: null
      })
        .then(async ({ data }) => {
          this.hotelList = data.allHotelsList
        })
        .catch((err) => {
          console.log({ err })
        })
    }
  },
  async created () {
    this.getHotels()
    this.activeIndex = this.$route.path
  }
}
</script>
<style lang="scss">
#HomePage {
  .nav-header {
    position: fixed;
    height: 60px;
    width: 100%;
    background: white;
    -webkit-box-shadow: 2px 3px 9px 0px rgba(0,0,0,0.23);
    box-shadow: 2px 3px 9px 0px rgba(0,0,0,0.23);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
  }
  .nav-menu {
    position: absolute;
    display: flex;
    z-index: 2;
  }
  .page-title {
    font-size: 32px;
    margin-left: 24px;
    font-weight: bold;
  }
  .el-menu {
    position: fixed;
    height: 100%;
    margin-top: 60px;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-menu-item {
    text-align: left;
  }
  .content {
    margin-top: 88px;
    width: 100%;
  }
  .menu-collapser {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    cursor:pointer;
    margin-left: 8px;
  }
  .menu-container {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .close-sesion {
    cursor: pointer;
    background: black;
    color: white;
    height: 100%;
    height: 60px;
    display: flex;
    width: max-content;
    padding: 0 16px;
    align-items: center;
  }
  .close-sesion-div {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
