<template lang="pug">
#UnfinishedSteps
  h3 Pasos inconclusos
  label Estado: 
  el-select(v-model="status")
    el-option(label="Todos", :value="null")
    el-option(label="Finalizado", value="FINISHED")
    el-option(label="No Finalizado", value="UNFINISHED")
  el-table(:data="provisoryData")
    el-table-column(prop="date" label="Fecha y hora")
      template(#default="{ row }")
        label {{ row.date | formatedDateTime }}
    el-table-column(prop="stepValue" label="Paso")
    el-table-column(prop="statusValue" label="Estado")
    el-table-column(prop="dataJson" label="JSON", width="500")
      template(#default="{ row }")
        json-viewer(:value="parsedJson(row.dataJson)", :expanded="false")
    el-table-column(label="Acciones")
      template(#default="{ row }")
        el-button(@click="saveProvisoryData(row.id, row.step, row.dataJson, row.reservation.confirmationNumber)") Rellenar información
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    JsonViewer,
  },
  data () {
    return {
      status: 'UNFINISHED',
    }
  },
  methods: {
    ...mapActions([
      'getReservationProvisoryData',
      'saveBasicInformationData',
      'updateTransport',
      'saveTravellerRequirements',
      'UpdatePhysicalConditions',
      'updateTravellerInterests',
      'updateTravellerDataMapi'
    ]),
    async init () {
      await this.getReservationProvisoryData({ reservationId: this.$route.params.reservationId, status: this.status })
    },
    parsedJson(json) {
      return this.snakeToCamel(JSON.parse(json.replaceAll('None', 'null').replaceAll('True', 'true').replaceAll('False', 'false').replaceAll("'", '"')))
    },
    async saveProvisoryData(id, step, json, confirmationNumber) {
      let parsedJson = this.snakeToCamel(JSON.parse(json.replaceAll('None', 'null').replaceAll('True', 'true').replaceAll('False', 'false').replaceAll("'", '"')))
      console.log(parsedJson)
      if (step == 'ARRIVAL_INFORMATION' || step == 'DEPARTURE_INFORMATION') {
        parsedJson = {
          ...parsedJson.transportInformation,
          ...parsedJson
        }
      }

      parsedJson.provisoryId = id
      parsedJson.confirmationNumber = confirmationNumber
      let resp = null
      switch (step) {
        case 'BASIC_INFORMATION': {
          resp = await this.saveBasicInformationData(parsedJson)
          break
        }
        case 'ARRIVAL_INFORMATION': {
          resp = await this.updateTransport(parsedJson)
          break
        }
        case 'DEPARTURE_INFORMATION': {
          resp = await this.updateTransport(parsedJson)
          break
        }
        case 'REQUIREMENTS_INFORMATION': {
          resp = await this.saveTravellerRequirements(parsedJson)
          break
        }
        case 'PHYSICAL_INFORMATION': {
          resp = await this.UpdatePhysicalConditions(parsedJson)
          break
        }
        case 'INTEREST_INFORMATION': {
          resp = await this.updateTravellerInterests(parsedJson)
          break
        }
        case 'MAPI_INFORMATION': {
          resp = await this.updateTravellerDataMapi(parsedJson)
          break
        }
      }
      if (resp) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡No Guardado!',
          message: 'Ha ocurrido un fallo al tratar de guardar',
          type: 'danger'
        })
      }
      this.init()
    },
    snakeToCamel(obj) {
      if (obj instanceof Array) {
        return obj.map((item) => this.snakeToCamel(item));
      } else if (obj instanceof Object) {
        return Object.keys(obj).reduce((acc, key) => {
          const camelKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
          acc[camelKey] = this.snakeToCamel(obj[key]);
          return acc;
        }, {});
      } else {
        return obj;
      }
    }
  },
  computed: {
    ...mapGetters(['provisoryData'])
  },
  created () {
    this.init()
  },
  watch: {
    status() {
      this.init()
    }
  }
}
</script>
<style lang="scss">
#UnfinishedSteps {
  text-align: left;
  padding: 100px;
}
</style>