import {
  getTravellerProfile,
  getInformation,
  getNationalities,
  saveTravellerProfileData,
  getHotelTransports,
  updateTransportRequirements,
  ReservationCancellTransport,
  getTagsConditions,
  saveTravellerConditions,
  saveTravellerFitnessConditions,
  getInterest,
  updateInterests,
  getCompanions,
  reservationProvisoryData,
  mutationUpdateTravellerDataMapi,
} from './apolloMutations.js'

export const getProfile = async ({ commit }, { token, confirmationNumber }) => {
  await getTravellerProfile({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRAVELLER_PROFILE', { travellerProfile: response.publicTravellerProfile })
    return response.publicTravellerProfile
  })
}

export const getFormInformation = async ({ commit }) => {
  await getNationalities().then(response => {
    commit('SET_NATIONALITIES', { nationalities: response.nationalities })
  })
  await getInformation({ informationType: 'COUNTRYCODES', countryCode: 'null' }).then(response => {
    commit('SET_COUNTRIES', { countries: response.information })
  })
}

export const saveBasicInformationData = async ({ commit }, {
  id, dni, name, lastName, gender, birthdate, nationality,
  country, state, city, address, email, phoneNumber, emailEmergency, phoneNumberEmergency,
  operaIdEmail, operaIdAddress, operaIdPhoneNumber, operaIdPhoneNumberEmergency, operaIdEmailEmergency, confirmationNumber, babySeat, provisoryId=null
}) => {
  const respons = await saveTravellerProfileData({
    id: id,
    dni: dni,
    name: name,
    lastName: lastName,
    gender: gender,
    birthdate: birthdate,
    nationality: nationality,
    country: country,
    state: state,
    city: city,
    // zipCode: zipCode,
    address: address,
    email: email,
    emailEmergency: emailEmergency,
    phoneNumber: phoneNumber,
    phoneNumberEmergency: phoneNumberEmergency,
    operaIdEmail: operaIdEmail,
    operaIdAddress: operaIdAddress,
    operaIdPhoneNumber: operaIdPhoneNumber,
    operaIdPhoneNumberEmergency: operaIdPhoneNumberEmergency,
    operaIdEmailEmergency: operaIdEmailEmergency,
    confirmationNumber: confirmationNumber,
    babySeat: babySeat,
    provisoryId: provisoryId,
  }).then(response => {
    if (!response.UpdateTravellerProfile.result) {
      console.log('Algo salió mal')
    }
    return response
  })

  return respons.UpdateTravellerProfile.result
}


export const getTransportInformation = async ({ commit }, { confirmationNumber }) => {
  await getHotelTransports({ confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRANSPORT', { transportsInformation: response.hotelTransportInformation })
  })
}

export const updateTransport = async ({ commit }, { travellerId, confirmationNumbers, transportId, transportType, carrierCode, locationCode, otherHotel, date, queryType, legNumber, warning, destinationType }) => {
  const resp = await updateTransportRequirements({
    travellerId: travellerId,
    confirmationNumbers: confirmationNumbers,
    transportId: transportId,
    transportType: transportType,
    carrierCode: carrierCode,
    locationCode: locationCode,
    otherHotel: otherHotel,
    date: date,
    queryType: queryType,
    legNumber: legNumber,
    warning: warning,
    destinationType: destinationType
  }).then(response => {
    return response
  })

  return resp.ModifyTransportRequirements.result
}

export const CancellTransport = async ({ commit }, { travellerId, confirmationNumber, legNumber, queryType, checkin, arrivesToLunch }) => {
  const resp = await ReservationCancellTransport({ travellerId: travellerId, confirmationNumber: confirmationNumber, legNumber: legNumber, queryType: queryType, checkin: checkin, arrivesToLunch: arrivesToLunch }).then(response => {
    return response
  })

  return resp.CancellTransport.result
}

export const getTags = async ({ commit }) => {
  await getTagsConditions().then(response => {
    commit('SET_TAGS_CONDITIONS', { tagsConditions: response.tagsConditions })
  })
}

export const saveTravellerRequirements = async ({ commit }, {
  id,
  hasFoodReq,
  hasMedicalReq,
  foodRequirements,
  medicalConditions,
  foodAllergiesTags,
  medicalDiseaseTags,
  medicalInjuryTags,
  medicalSurgeryTags,
  medicalAllergiesTags,
  confirmationNumber,
  foodRestrictions,
  otherMedicalConditions
}) => {
  const response = await saveTravellerConditions({
    travellerId: id,
    hasFoodReq: hasFoodReq,
    hasMedicalReq: hasMedicalReq,
    foodRequirements: foodRequirements,
    medicalConditions: medicalConditions,
    foodAllergiesTags: foodAllergiesTags,
    medicalDiseaseTags: medicalDiseaseTags,
    medicalInjuryTags: medicalInjuryTags,
    medicalSurgeryTags: medicalSurgeryTags,
    medicalAllergiesTags: medicalAllergiesTags,
    confirmationNumber: confirmationNumber,
    foodRestrictions: foodRestrictions,
    otherMedicalConditions: otherMedicalConditions
  })
  if (!response.UpdateTravellerConditions.result) {
    console.log('Algo salió mal')
  }

  return response.UpdateTravellerConditions.result
}

export const UpdatePhysicalConditions = async ({ commit }, { travellerId, confirmationNumber, physicalState, fitnessIntensity, provisoryId }) => {
  const resp = await saveTravellerFitnessConditions({ travellerId: travellerId, confirmationNumber: confirmationNumber, physicalState: physicalState, fitnessIntensity: fitnessIntensity, provisoryId: provisoryId }).then(response => {
    commit('SET_TAGS_CONDITIONS', { tagsConditions: response.tagsConditions })
    if (!response.UpdatePhysicalConditions.result) {
      console.log('Algo salió mal')
    }
    return response
  })

  return resp.UpdatePhysicalConditions.result
}

export const getInterests = async ({ commit }, { confirmationNumber }) => {
  await getInterest({ confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_INTERESTS', { interests: response.travellerInterests })
  })
}

export const updateTravellerInterests = async ({ commit }, { travellerId, confirmationNumber, travellerInterests, commentary, provisoryId }) => {
  const resp = await updateInterests({ travellerId: travellerId, confirmationNumber: confirmationNumber, travellerInterests: travellerInterests, commentary: commentary, provisoryId: provisoryId }).then(response => {
    return response
  })
  return resp.UpdateTravellerInterests.result
}

export const updateTravellerDataMapi = async (
  { commit },
  {
    confirmationNumber, id, dni, name,
    lastName, birthdate, nationality, provisoryId
  }
) => {
  const res = await mutationUpdateTravellerDataMapi({
    confirmationNumber: confirmationNumber,
    id: id,
    dni: dni,
    name: name,
    lastName: lastName,
    birthdate: birthdate,
    nationality: nationality,
    provisoryId: provisoryId
  })
  return res.UpdateTravellerDataMapi.result
}

export const getTravellerCompanions = async ({ commit }, { confirmationNumber }) => {
  await getCompanions({ confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_COMPANIONS', { companions: response.reservationsCompanions })
    return response
  })
}

export const getReservationProvisoryData = async ({ commit }, { reservationId, status }) => {
  await reservationProvisoryData({ reservationId: reservationId, status: status }).then(response => {
    commit('SET_PROVISORY_DATA', { provisoryData: response.reservationProvisoryData })
    return response
  })
}
