<template lang="pug">
#TravellerInterests
  h2(v-if="interestsComo.length > 0") Qué actividades te gustaría realizar? (Selecciona todas las que quieras)
  .icons
    div(v-for="i in interestsComo", v-bind:key="i.id")
      input(type="checkbox", :id="'myCheckbox' + i.id", :value="i.code"  v-model="checkedComo")
      label(:for="'myCheckbox' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2(v-if="interestsList.length > 0") ¿Cuales son tus intereses? (Selecciona todas las que quieras)
  .icons
    div(v-for="i in interestsList", v-bind:key="i.id")
      input(type="checkbox", :id="'myCheckbox' + i.id", :value="i.code"  v-model="checkedInterests")
      label(:for="'myCheckbox' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2(v-if="interestsQueEsperas.length > 0") ¿Cuál es el propósito de tu viaje?
  .icons
    div(v-for="i in interestsQueEsperas", v-bind:key="i.id")
      input(type="checkbox", :id="'myCheckbox' + i.id", :value="i.code"  v-model="checkedQueEsperas")
      label(:for="'myCheckbox' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2 Comentario
  el-input(v-model="commentary")
  .button-group
    el-button#explora-btn.primary(@click="saveData") GUARDAR
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      checkedComo: [],
      checkedInterests: [],
      checkedQueEsperas: [],
      interestsComo: [],
      interestsList: [],
      interestsQueEsperas: [],
      commentary: ''
    }
  },
  computed: {
    ...mapGetters(['interests', 'travellerProfile'])
  },
  methods: {
    ...mapActions(['updateTravellerInterests']),
    async saveData () {
      this.$notify({
        title: 'Guardando cambios...',
        message: 'Por favor no cierre el navegador',
        type: 'warning'
      })
      const interests = []
      this.checkedComo.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })
      this.checkedInterests.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })
      this.checkedQueEsperas.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })
      const resp = await this.updateTravellerInterests({ travellerId: this.travellerProfile.id, confirmationNumber: this.travellerProfile.reservationConsulted.confirmationNumber, travellerInterests: interests, commentary: this.commentary })
      this.$notify({
        title: '¡Guardado!',
        message: 'Los datos han sido actualizados exitósamente',
        type: 'success'
      })
      if (resp) {
        this.$notify({
          title: '¡Guardado!',
          message: 'Los datos han sido actualizados exitósamente',
          type: 'success'
        })
      } else {
        this.$notify({
          title: '¡Error!',
          message: 'Ha sucedido un problema, por favor inténtalo más tarde',
          type: 'error'
        })
      }
    }
  },
  created () {
    this.commentary = this.travellerProfile.reservationConsulted.interestComment
    this.interestsComo = this.interests.filter(interest => interest.clasification === 'COMO')
    this.interestsList = this.interests.filter(interest => interest.clasification === 'INTERES')
    this.interestsQueEsperas = this.interests.filter(interest => interest.clasification === 'QUE_ESPERAS')

    const como = this.travellerProfile.interests.filter(interest => interest.clasification === 'COMO')
    como.forEach(interest => {
      this.checkedComo.push(interest.code)
    })
    const interests = this.travellerProfile.interests.filter(interest => interest.clasification === 'INTERES')
    interests.forEach(interest => {
      this.checkedInterests.push(interest.code)
    })
    const queEsperas = this.travellerProfile.interests.filter(interest => interest.clasification === 'QUE_ESPERAS')
    queEsperas.forEach(interest => {
      this.checkedQueEsperas.push(interest.code)
    })
  }
}
</script>
<style lang="scss">
#TravellerInterests{
  text-align: left;
  padding: 8px 24px;
  .button-group {
    margin-top: 16px;
  }
  h6 {
    margin-top: 0px !important;
  }
  input[type="checkbox"][id^="myCheckbox"] {
    display: none;
  }
  .icons {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .icon-circle {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dbdde0;
    color: #4b5763;
  }
  label {
    margin-bottom: 1px !important;
  }
  @media (max-width: 375px) {
    .icon-circle {
      width: 67px;
      height: 67px;
    }
    label {
      margin: 9px !important;
    }

    label:before {
      top: 51px !important;
      left: 59px !important;
    }
  }
  @media (max-width: 320px) {
    .icon-circle {
      width: 50px;
      height: 50px;
    }

    label:before {
      top: 38px !important;
      left: 44px !important;
    }
  }
  label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #f7f1f1;
    position: absolute;
    top: 67px;
    left: 73px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  :checked + label:before {
    content: "✓";
    background-color: rgb(58, 214, 118);
    transform: scale(1);
  }
}
</style>
