<template lang="pug">
#DataTable
  h1 Viajeros similares
  div#scroll(style="width: 92vw; height: 70vh; overflow: scroll;")
    div(style="display: flex;", v-if="loading")
      spinner
      .loading-txt(style="padding-top: 16px; padding-left: 16px;") Cargando viajeros...
    .fixed-btn(style="display: flex;", v-if="existSelected")
      el-button.btn-similar(type="primary", :disabled="!existSelected", @click="acceptOrRejectMultiple(true)") Aceptar similitudes
      el-button.btn-similar(type="primary", :disabled="!existSelected", @click="acceptOrRejectMultiple(false)") Rechazar similitudes
    el-tabs(type="card" v-model="filter" @tab-click="reload()")
      el-tab-pane.a(label="Por revisar" name="revisar")
      el-tab-pane.a(label="4 Semanas" name="4_semanas")
      el-tab-pane.a(label="4 Semanas Antes" name="4_semanas_antes")
      el-tab-pane.a(label="Aceptadas" name="aceptadas")
      el-tab-pane.a(label="Rechazadas" name="rechazadas")
    div(style="margin-bottom: 8px;", v-if="filter !== '4_semanas' && filter !== '4_semanas_antes'")
      label Año de checkout:
      el-select(v-model="selectedYear", size="mini", style="margin-left: 12px;")
        el-option(label="Todos", :value="null")
        el-option(:label="year", :value="year", v-for="year in years")
    div(style="margin-bottom: 8px;", v-if="filter !== '4_semanas' && filter !== '4_semanas_antes'")
      label Sólo con Checked-out:
      el-select(v-model="checkedout", size="mini", style="margin-left: 12px;" :disabled="selectedYear !== null")
        el-option(label="No", :value="false")
        el-option(label="Sí", :value="true")
    div(style="margin-bottom: 8px;", v-if="filter !== '4_semanas' && filter !== '4_semanas_antes'")
      h5 Hoteles activos: {{ activeHotels }}
    table.explora-table
      thead
        th Seleccionar
        th Id opera
        th Viajero original
        th Último viaje
        th Id opera
        th Viajero Similar
        th Último viaje
        th Puntaje total
      tbody
        tr(v-for="(r, index) in similarTravelersData" v-bind:key="index")
          td
            el-checkbox(v-model="r.selected")
          td {{ r.original_member.opera_traveller_id }}
          td {{ r.original_member.first_name +' '+ r.original_member.last_name}}
          td(style="text-align: left;")
            div {{ r.original_member.last_transaction ? `Estado: ${r.original_member.last_transaction.status}` : 'Sin Reservas' }}
            div {{ r.original_member.last_transaction ? `Check-in: ${getFormatedDate(r.original_member.last_transaction.checkin)}` : '' }}
            div {{ r.original_member.last_transaction ? `Destino: ${r.original_member.last_location_name}` : '' }}
            div {{ r.original_member.last_transaction ? `Categoría: ${r.original_member.category}` : '' }}
          td {{ r.comparable_member.opera_traveller_id }}
          td {{ r.comparable_member.first_name +' '+ r.comparable_member.last_name}}
          td(style="text-align: left;")
            div {{ r.comparable_member.last_transaction ? `Estado: ${r.comparable_member.last_transaction.status}` : 'Sin Reservas'}}
            div {{ r.comparable_member.last_transaction ? `Check-in: ${getFormatedDate(r.comparable_member.last_transaction.checkin)}` : '' }}
            div {{ r.comparable_member.last_transaction ? `Destino: ${r.comparable_member.last_location_name}` : '' }}
            div {{ r.comparable_member.last_transaction ? `Categoría: ${r.comparable_member.category}` : '' }}
          td {{ r.total_score }}
          td
            el-button.btn-similar(type="primary", @click="seeDetails(r.id)") Ver detalles

  transition(name="modal" v-if="showModal == true && originalTraveler")
    .modal-mask
      .modal-wrapper
        .modal-container
          .modal-header
            slot(name="header")
              center
                h4 Detalle de viajeros similares
              h4 Puntaje total de similitud: {{ similarTravelerDetail.total_score }}
          .modal-body
            table.explora-table
              thead
                th Atributo
                th Puntaje
                th Miembro original
                th Miembro similar
              tbody
                tr Nombre
                  td {{ similarTravelerDetail.first_name }}
                  td {{ originalTraveler.first_name ? originalTraveler.first_name : 'No registrado'}}
                  td {{ comparableTraveler.first_name ? comparableTraveler.first_name : 'No registrado'}}
                tr Apellido
                  td {{ similarTravelerDetail.last_name }}
                  td {{ originalTraveler.last_name ? originalTraveler.last_name : 'No registrado'}}
                  td {{ comparableTraveler.last_name ? comparableTraveler.last_name : 'No registrado'}}
                tr Email
                  td {{ similarTravelerDetail.email }}
                  td {{ originalTraveler.email ? originalTraveler.email : 'No registrado'}}
                  td {{ comparableTraveler.email ? comparableTraveler.email : 'No registrado'}}
                tr Teléfono
                  td {{ similarTravelerDetail.phone }}
                  td {{ originalTraveler.phone ? originalTraveler.phone : 'No registrado'}}
                  td {{ comparableTraveler.phone ? comparableTraveler.phone : 'No registrado'}}
                tr Pasaporte
                  td {{ similarTravelerDetail.passport }}
                  td {{ originalTraveler.passport ? originalTraveler.passport : 'No registrado'}}
                  td {{ comparableTraveler.passport ? comparableTraveler.passport : 'No registrado'}}
                tr Fecha de nacimiento
                  td {{ similarTravelerDetail.birthdate }}
                  td {{ originalTraveler.birthdate ? originalTraveler.birthdate : 'No registrado' }}
                  td {{ comparableTraveler.birthdate ? comparableTraveler.birthdate : 'No registrado' }}
                tr Id Opera
                  td {{ similarTravelerDetail.opera_traveller_id }}
                  td {{ originalTraveler.opera_traveller_id ? originalTraveler.opera_traveller_id : 'No registrado' }}
                  td {{ comparableTraveler.opera_traveller_id ? comparableTraveler.opera_traveller_id : 'No registrado' }}
                tr País
                  td {{ similarTravelerDetail.country }}
                  td {{ originalTraveler.country ? originalTraveler.country : 'No registrado' }}
                  td {{ comparableTraveler.country ? comparableTraveler.country : 'No registrado' }}
                tr Nacionalidad
                  td {{ similarTravelerDetail.nationality }}
                  td {{ originalTraveler.nationality ?  originalTraveler.nationality : 'No registrado' }}
                  td {{ comparableTraveler.nationality ?  comparableTraveler.nationality : 'No registrado' }}
                tr Género
                  td {{ similarTravelerDetail.gender }}
                  td {{ originalTraveler.gender ? originalTraveler.gener : 'No registrado' }}
                  td {{ comparableTraveler.gender ? comparableTraveler.gener : 'No registrado' }}
          .modal-footer
            el-button.btn-similar(type="primary" @click="showModal=false") Cerrar
            div(v-if="filter==='revisar' ||filter==='4_semanas' ")
              el-button.btn-similar(type="primary" @click="rejectOrAcceptSimilarTraveler(similarTravelerDetail.id, reject=true)") Descartar
              el-button.btn-similar(type="primary" @click="rejectOrAcceptSimilarTraveler(similarTravelerDetail.id, accept=true)") Aceptar similitud
            div(v-else)
              el-button.btn-similar(type="primary" @click="rejectOrAcceptSimilarTraveler(similarTravelerDetail.id, reject=null, accept=null)") Deshacer {{ filter==='aceptadas' ? 'aceptación' : 'rechazo' }}

</template>
<script>
import moment from 'moment'
import Spinner from '../components/Spinner'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Spinner
  },
  data () {
    return {
      showModal: false,
      originalTraveler: null,
      comparableTraveler: null,
      similarTravelersData: [],
      loading: false,
      page: 1,
      accept: null,
      reject: null,
      checkedout: false,
      filter: 'revisar',
      years: [],
      selectedYear: null
    }
  },
  created () {
    this.createYears()
    this.loadMoreTravelers()
  },
  mounted () {
    const listElm = document.querySelector('#scroll')
    listElm.addEventListener('scroll', e => {
      if (Math.ceil(listElm.scrollTop) + listElm.clientHeight >= listElm.scrollHeight && this.loading === false) {
        this.loadMoreTravelers()
      }
    }, true)
  },
  computed: {
    ...mapGetters(['similarTravelerDetail', 'user']),
    activeHotels () {
      const hotelsNames = []
      if (this.user) {
        this.user.hotels.forEach((h) => {
          hotelsNames.push(h.name)
        })
      }
      return hotelsNames.join(', ')
    },
    existSelected () {
      return this.similarTravelersData.filter((sm) => sm.selected).length > 0
    },
    activeHotelsCodes () {
      const hotelsNames = []
      if (this.user) {
        this.user.hotels.forEach((h) => {
          hotelsNames.push(h.code)
        })
      }
      return hotelsNames.join(';')
    }
  },
  methods: {
    ...mapActions(['getSimilarTravelerDetail', 'listSimilarTravelers', 'updateSimilarTravelerDetail']),
    acceptOrRejectMultiple (accept) {
      this.reject = null
      this.accept = null
      const action = accept ? 'Aceptar masivamente' : 'Rechazar masivamente'

      this.$confirm('¿Estás seguro que deseas realizar esta acción?', action, {
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Acción realizada con éxito'
        })
        if (accept) {
          this.similarTravelersData.filter((sm) => sm.selected).forEach((sm) => {
            this.accept = true
            this.rejectOrAcceptSimilarTraveler(sm.id)
          })
        } else {
          this.similarTravelersData.filter((sm) => sm.selected).forEach((sm) => {
            this.reject = true
            this.rejectOrAcceptSimilarTraveler(sm.id)
          })
        }
      }).catch(() => {})
    },
    createYears () {
      const actualYear = new Date().getFullYear()
      let index = 0
      do {
        const yearBefore = actualYear - index
        this.years.push(yearBefore)
        index++
      } while (index < 15)
    },
    async seeDetails (id) {
      this.showModal = true
      await this.getSimilarTravelerDetail({ id: id })
      if (this.similarTravelerDetail) {
        this.originalTraveler = this.similarTravelerDetail.original_member
        this.comparableTraveler = this.similarTravelerDetail.comparable_member
      }
    },
    reload () {
      this.similarTravelersData = []
      this.page = 1
      this.loadMoreTravelers()
    },
    getFormatedDate (date) {
      return moment(date).locale('es').format('DD/MM/YYYY')
    },
    async loadMoreTravelers () {
      this.loading = true
      const fourWeeks = this.filter === '4_semanas'
      const fourWeeksBefore = this.filter === '4_semanas_antes'
      const filter = this.filter === '4_semanas' || this.filter === '4_semanas_antes' ? 'revisar' : this.filter
      const hotels = this.activeHotelsCodes
      console.log(hotels)

      const response = await this.listSimilarTravelers({ page: this.page, filter: filter, checkedout: this.checkedout, year: this.selectedYear, fourWeeksAfter: fourWeeks, fourWeeksBefore: fourWeeksBefore, hotels: hotels })
      if (this.similarTravelersData.length === response.count) {
        this.loading = false
      } else {
        response.results.forEach(d => {
          this.similarTravelersData.push({ ...d, selected: false })
        })
      }
      this.loading = false
      if (response.next) {
        this.page++
      }
    },
    async rejectOrAcceptSimilarTraveler (id) {
      const data = { accept: this.accept, reject: this.reject, reviewer_email: this.user.email }
      await this.updateSimilarTravelerDetail({ id: id, data: data })
      this.showModal = false
      this.accept = null
      this.reject = null
      this.similarTravelersData = [...this.similarTravelersData.filter((sm) => sm.id !== id)]
      this.page = 1
      this.loadMoreTravelers()
    }
  },
  watch: {
    // filter: function () {
    //   this.similarTravelersData = []
    //   this.page = 1
    //   this.checkedout = false
    // },
    checkedout: function () {
      if (this.selectedYear === null) {
        this.reload()
      }
    },
    selectedYear: function () {
      this.checkedout = true
      this.reload()
    }
  }
}
</script>
<style lang="scss">
#DataTable {
  text-align: left;
  th {
    cursor:pointer;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .blue-circle {
    background: #409eff;
  }
  .orange-circle {
    background: #f1a33c;
  }
  .search-input {
    width: 250px;
    margin-bottom: 20px;
    padding: 8px;
  }
  .tool-tip-label {
    border-radius: 50%;
    width: 20px;
    background:white;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    height: 38px;
  }
  .explora-table {
    text-align: center;
    width: 100%;
    tr:nth-child(even) {background-color: #f7f7f7;}
    th, td {
      padding: 8px;
    }
    th {
      color: #1b1b1b;
      white-space: nowrap;
    }
    tr {
      vertical-align: baseline;
    }
  }
  h1 {
    text-align: left;
    margin-bottom: 24px;
    margin-top: -34px;
  }
  .selected-sort {
    font-weight: bold;
    color: #7a7a7a !important;
  }
  .btn-similar {
    width: max-content;
    margin: 10px;
  }
}
.toasted.done {
    background-color: #f1a33c !important;
    color: white;
    padding: 8px;
    border-radius: 13px
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.flexbox-container {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
}
.flexbox-container > div {
        width: 50%;
        padding: 10px;
}
.flexbox-container > div:first-child {
        margin-right: 20px;
}
.values {
  line-height: 15px;
}
.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  pointer-events: none;
}
.is-disabled {
  opacity: 0.7;
}
.fixed-btn {
  position: fixed;
  width: 200px;
  height: 65px;
  z-index: 1;
  top: 70px;
  right: 200px;
  background-color: white;
}
</style>
