import axios from 'axios'
import settings from '../../../settings'
import * as apollo from './apolloMutations.js'

export const futureReservationsMapi = async (
  _,
  { page, weeks, hotelId, criticalFields, aditionalFields, forTicketsMode }
) => {
  return apollo
    .futureReservationsMapi({
      page,
      weeks,
      hotelId,
      aditionalFields,
      forTicketsMode,
    })
    .then((response) => {
      return response.futureReservationsMapi
    })
}

export const updateMapiPreferredDate = async (
  _,
  { mapiPreferredDate, confirmationNumber, legNumber }
) => {
  const res = await apollo.mutationUpdateMapiPreferredDate({
    mapiPreferredDate: mapiPreferredDate,
    confirmationNumber: confirmationNumber,
    legNumber: legNumber,
  })
  return res.UpdateMapiPreferredDate.result
}

export const updateTravellerDataMapi = async (
  _,
  { confirmationNumber, id, dni, name, lastName, birthdate, nationality }
) => {
  const res = await apollo.mutationUpdateTravellerDataMapi({
    confirmationNumber: confirmationNumber,
    id: id,
    dni: dni,
    name: name,
    lastName: lastName,
    birthdate: birthdate,
    nationality: nationality,
  })
  return res.UpdateTravellerDataMapi.result
}

export const getMapiMessages = async () => {
  return await apollo.queryGetMapiMessages()
}

export const setApprovedMapi = async (
  _,
  { mapiVisitId, approved, applyParty }
) => {
  const res = await apollo.mutationSetApprovedMapi({
    mapiVisitId,
    approved,
    applyParty,
  })
  return res.SetApprovedMapi.result
}

export const exportMapiData = async (_, params) => {
  const url = `${settings.graphqlBase}/operation/export_mapi_data`
  return axios
    .get(url, { params, responseType: 'blob' })
    .then((response) => response)
    .catch((err) => err.response)
}
