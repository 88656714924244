import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createProvider } from './vue-apollo'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Toasted from 'vue-toasted'
import '../plugins/fontAwesome'
import moment from 'moment'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import settings from '../settings'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Toasted)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: settings.sentryDsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}

Vue.filter('formatedDate', function (value) {
  if (value && value !== '') {
    return moment(value).format('DD-MM-YYYY')
  }
  return ''
})

Vue.filter('formatedDateTime', function (value) {
  if (value && value !== '') {
    return moment(value).format('DD/MM/YYYY, HH:mm')
  }
  return ''
})

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
