<template lang="pug">
#Companions
  //- .title(v-if="companions.length > 0") Acompañantes
  br
  .traveller(v-for="companion in companions", @click="goToTp(companion.tokenTravellerProfile, companion.confirmationNumber)")
    .companion-avatar
      img(v-if="companion.travellerObj.avatarUrl" :src="companion.travellerObj.avatarUrl")
      .big-letter(v-else) {{ companion.travellerObj.firstName[0] }}
    .name {{ companion.travellerObj.firstName }} {{ companion.travellerObj.lastName }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions(['getTravellerCompanions']),
    goToTp (token, confirmationNumber) {
      const routeData = this.$router.resolve({ path: `/travellerProfile/modifyProfile/${token}/confirmationNumber/${confirmationNumber}` })
      window.open(routeData.href, '_blank')
    }
  },
  computed: {
    ...mapGetters(['companions'])
  },
  created () {
    this.getTravellerCompanions({ confirmationNumber: this.$route.params.confirmationNumber })
  }
}
</script>
<style lang="scss">
#Companions {
  display: flex;
  justify-content: center;
  .traveller {
    margin-top: 24px;
    margin-right: 8px;
    text-align: -webkit-center;
    cursor: pointer;
  }
  .companion-avatar {
    width: 55px;
    height: 55px;
    background: aliceblue;
    border: 1px solid #80808059;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .big-letter {
    color: #0000008c;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>
