<template lang="pug">
#Login
  .login-box
    .logo
      img(src="../img/logo-2.png", alt="")
      //- img(src="../img/logo.jpg", alt="")
    el-form(ref="form", :model="form", @submit.native.prevent="login")
      el-form-item(label="DNI")
        el-input.login-input(placeholder="Ingresa DNI", v-model="form.dni")
      el-form-item(label="Contraseña")
        el-input.login-input(placeholder="Ingresa contraseña", type="password", v-model="form.password")
      el-form-item.btn
        el-button(:loading="loading", type="primary", :disabled="form.dni === '' || form.password === ''", native-type="submit") Ingresar
</template>
<script>
import gql from 'graphql-tag'
import { onLogin } from '../vue-apollo'

export default {
  data () {
    return {
      form: {
        dni: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    async login () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation($username: String!, $password: String!) {
            Login(username: $username, password: $password) {
              profile {
                name
                userName
                position
                email
                permissions
                hotels {
                  id
                  name
                  code
                }
              }
            }
          }
        `,
        client: 'exploraPublic',
        variables: {
          username: this.form.dni,
          password: this.form.password
        }
      })
        .then(async ({ data }) => {
          if (data.Login.profile.position === 'Agente' || data.Login.profile.position === 'Administrador') {
            this.loading = false
            const apolloClient = this.$apollo.provider.defaultClient
            await onLogin(apolloClient, true)
            this.$store.commit('SET_USER', { user: data.Login.profile })
            this.$router.push('/reservations/agentReservations')
          } else {
            this.loading = false
            this.$toasted.show('El usuario debe ser agente o administrador', {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000
            })
          }
        })
        .catch((err) => {
          const exception = { err }
          this.loading = false
          this.$toasted.show(exception.err.graphQLErrors[0].code, {
            theme: 'bubble',
            position: 'top-right',
            duration: 5000
          })
        })
    }
  }
}
</script>
<style lang="scss">
#Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 200px;
  text-align: left;
  .login-input {
    margin-bottom: 24px;
    width: 100%;
  }
  .login-box {
    width: 80%;
    max-width: 500px;
  }
  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 40%;
      margin-bottom: 32px;
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.toasted.bubble {
  background-color: #e82d4a !important;
}
</style>
