<template lang="pug">
#MapiReservations
  el-tabs(v-model="currentTab")
    el-tab-pane(label="Info por completar" name="missingInfo")
      MissingInfo(v-if="currentTab === 'missingInfo'")
    el-tab-pane(label="Calendario" name="calendar")
      MapiCalendar(v-if="currentTab === 'calendar'")
    //- el-tab-pane(label="Tickets Listos" name="tickets")
      //- tickets-ready(v-if="currentTab === 'tickets'")
</template>

<script>
import { Tabs, TabPane } from 'element-ui'
import MissingInfo from '@/components/MapiReservations/MissingInfo.vue'
import MapiCalendar from '@/components/MapiReservations/MapiCalendar.vue'

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    MissingInfo,
    MapiCalendar,
  },

  data() {
    return {
      currentTab: 'missingInfo',
    }
  },
}
</script>

<style lang="scss" scoped>
#MapiReservations {
  padding: 70px 30px 0 94px;
}
</style>
