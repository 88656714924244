import mutations from './mutations'
import * as getters from './getters'

const TravellersStore = {
  state: {
    user: null
  },
  getters,
  mutations
}

export default TravellersStore
