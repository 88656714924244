<template lang="pug">
#TravellerMetrics
  .menu-div
    label(style="margin: 10px;") Rango de fechas:
    el-date-picker(v-model="dateRange", format="dd-MM-yyyy" type="daterange", range-separator="A", start-placeholder="Fecha inicio", end-placeholder="Fecha fin")
  .menu-div
    label(style="margin: 10px;") Hoteles:
    el-select(v-model="hotel", multiple)
      el-option(v-for="hotel in hotelList" :label="hotel.name", :value="hotel.id")
    label(style="margin: 10px;") Agente:
    el-select(v-model="agent", multiple, filterable)
      el-option(v-for="agentObj in agents" :label="agentObj.username", :value="agentObj.username")
    label(style="margin: 10px;") Agencia:
    el-select(v-model="agency", filterable, multiple)
      el-option(v-for="agencyObj in agencies" :label="agencyObj.name", :value="agencyObj.id")
    label(style="margin: 10px;") Nacionalidad:
    el-select(v-model="nationality", filterable)
      el-option(label="Todas", :value="''")
      el-option(v-for="nat in nationalities" :label="nat.description", :value="nat.value")
    label(style="margin: 10px;") Status reserva:
    el-select(v-model="statusReservation", multiple)
        el-option(label="WAITLISTED", value="WAITLISTED")
        el-option(label="INHOUSE", value="INHOUSE")
        el-option(label="RESERVED", value="RESERVED")
        el-option(label="CANCELED", value="CANCELED")
        el-option(label="CHECKEDOUT", value="CHECKEDOUT")
    br
    br
    el-button(@click="search()", :loading="cancelledOperation", type="primary") Buscar
  .tables
    .table-div(v-if="dataTable && dataTableExplora", v-for="(data, index) in metricsReports")
      h4 {{ index === 0 ? 'Agencias' : 'Directo' }}
      h4 Total: {{ data.reservationsCount }}
      table.bordered
        thead
          th Criterios
          th.bold-left Porcentaje todos los viajeros
          th Cantidad de viajeros
        tbody
          tr
            td.flex-div
              span Links copiados
              el-tooltip(class="item" effect="dark" content="% de links que se copian para ser enviados a los viajeros" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left  {{ data.copiedPercentage }} %
            td {{ data.copiedTravellerCount }}
          tr
            td.flex-div
              span Emails enviados a viajeros
              el-tooltip(class="item" effect="dark" content='% de viajeros a los que se les envía el TP mediant el botón "Enviar correo"' placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.emailSentPercentage }} %
            td {{ data.emailSentTravellerCount }}
          tr
            td.flex-div
              span Total enviados a viajeros
              el-tooltip(class="item" effect="dark" content="% total de envíos a los viajeros (ya sea copiado, enviado por correo o ambas)" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.workedPercentage }} %
            td {{ data.workedTravellerCount }}
          tr
            td.flex-div
              span TP abiertos por viajeros
              el-tooltip(class="item" effect="dark" content="% de viajeros que abren su traveller profile por primera vez" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.tpOpenedPercentage }} %
            td {{ data.tpOpenedTravellerCount }}
          tr
            td.flex-div
              span Reservas con datos mínimos
              el-tooltip(class="item" effect="dark" content="% de viajeros que tienen completados: Nombre, apellido, DNI, transporte, nacimiento, nacionalidad y género (Desde el TP)" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.minDataPercentage }} %
            td {{ data.minDataTravellerCount }}
          tr
            td.flex-div
              span Respuesta completa TP
              el-tooltip(class="item" effect="dark" content="% de viajeros que tienen completados todos sus pasos de traveller profile (Desde el TP)" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.responsePercentage }} %
            td {{ data.responseTravellerCount }}
          tr
            td.flex-div
              span WebCheckin Pasaporte
              el-tooltip(class="item" effect="dark" content="% de viajeros que tienen completados su pasaporte en el Web Checkin" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.webCheckinPassportStepPercentage }} %
            td {{ data.webCheckinPassportStepTravellerCount }}
          tr
            td.flex-div
              span WebCheckin Firma
              el-tooltip(class="item" effect="dark" content="% de viajeros que tienen firmado el waiver en el Web Checkin" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.webCheckinSignatureStepPercentage }} %
            td {{ data.webCheckinSignatureStepTravellerCount }}
          tr
            td.flex-div
              span WebCheckin País
              el-tooltip(class="item" effect="dark" content="% de viajeros que tienen completado la información de residencia" placement="top-start")
                el-button.tool-tip-label ?
            td.bold-left {{ data.webCheckinCountryStepPercentage }} %
            td {{ data.webCheckinCountryStepTravellerCount }}
  spinner(v-if="loading")
  .table-div(v-if="reservations.length > 0")
    el-table(:data="reservations", :cell-class-name="getCellClassName")
      el-table-column(prop="confirmationNumber", label="Confirmation Number", width="220px", sortable)
      el-table-column(prop="checkin", label="Check-in", width="170px", sortable)
        template(slot-scope="scope")
          label {{ formatedDate(scope.row.checkin) }}
      el-table-column(prop="traveller", label="Viajero", width="200px", sortable)
        template(slot-scope="scope")
          label(v-if="scope.row.traveller.length > 0") {{ scope.row.traveller[0].firstName + ' ' + scope.row.traveller[0].lastName }}
      el-table-column(prop="isCompletedMin", label="Datos mínimos completados", width="148px", sortable)
        template(slot-scope="scope")
          .flex-div
            label {{ scope.row.isCompletedMin ? 'Sí' : 'No' }}
            el-tooltip(v-if="scope.row.isCompletedMin === false", :value="activeTooltip === scope.row.id", :manual="true", class="item" effect="dark" placement="right")
              div(slot="content")
                div(v-loading="loadingTooltip", element-loading-spinner="el-icon-loading", style="min-width: 100px; min-height: 20px;")
                  span(v-if="!loadingTooltip") {{ tooltipContent }}
              el-button.tool-tip-label(@click="setActiveTooltip(scope.row.id)") ?
      el-table-column(prop="isTravellerProfileManipulatedByAgent", label="Modificado por agente", width="148px", sortable)
        template(slot-scope="scope")
          .flex-div
            label {{ scope.row.isTravellerProfileManipulatedByAgent ? 'Sí' : 'No' }}
      el-table-column(prop="stepsCompleted", label="Pasos completados TP", width="195px", sortable)
      el-table-column(prop="isTravellerProfileOpened", label="TP Abierto por el viajero", width="155px", sortable)
        template(slot-scope="scope")
          label {{ scope.row.isTravellerProfileOpened ? 'Sí' : 'No' }}
      el-table-column(prop="emailTpSent", label="Email enviado", width="165px", sortable)
        template(slot-scope="scope")
          label {{ scope.row.emailTpSent ? 'Sí' : 'No' }}
      el-table-column(prop="isTpLinkCopied", label="Link enviado", width="155px", sortable)
        template(slot-scope="scope")
          label {{ scope.row.isTpLinkCopied ? 'Sí' : 'No' }}
      el-table-column(prop="isReservationWorked", label="Enviado a viajero", width="185px", sortable)
        template(slot-scope="scope")
          label {{ scope.row.isReservationWorked ? 'Sí' : 'No' }}
      el-table-column(prop="legNumber", label="leg Number", width="140px", sortable)
      el-table-column(prop="partyCode", label="Party", width="130px", sortable)
      el-table-column(prop="checkout", label="Check-out", width="170px", sortable)
        template(slot-scope="scope")
          label {{ formatedDate(scope.row.checkout) }}
      el-table-column(prop="traveller", label="Correo electrónico", width="200px", sortable)
        template(slot-scope="scope")
          label(v-if="scope.row.traveller.length > 0") {{ scope.row.traveller[0].email }}
      el-table-column(prop="agentUsername", label="Agente", width="150px", sortable)
      el-table-column(prop="hotel", label="Hotel", width="150px", sortable)
        template(slot-scope="scope")
          label {{ scope.row.hotel.name }}
      el-table-column(prop="reservationStatus", label="Estado de reserva", width="190px", sortable)
</template>
<script>
import gql from 'graphql-tag'
import moment from 'moment'
import Spinner from '../components/Spinner'

export default {
  components: {
    Spinner
  },
  data () {
    return {
      dateRange: null,
      hotel: [],
      agent: [],
      cancelledOperation: false,
      agents: [],
      nationality: '',
      nationalities: [],
      statusReservation: ['RESERVED'],
      hotelList: [],
      dataTable: null,
      dataTableExplora: null,
      loading: false,
      reservations: [],
      agency: [],
      agencies: [],
      exploraAgencies: [],
      activeTooltip: '',
      loadingTooltip: false,
      tooltipContent: '',
      queryParams: 'page pages hasNext hasPrev objects { id confirmationNumber legNumber partyCode traveller { firstName lastName nationality email } hotel { name } checkin checkout agentUsername reservationStatus stepsCompleted isCompletedMin isCompletedAgent isCompletedMinAgent isTravellerProfileManipulatedByAgent emailTpSent isTravellerProfileOpened isTpLinkCopied hasUnfinishedSteps isReservationWorked }'
    }
  },
  computed: {
    metricsReports () {
      return [this.dataTable, this.dataTableExplora]
    }
  },
  created () {
    const now = new Date()
    const twoWeeksNext = new Date().setDate(new Date().getDate() + 14)
    this.dateRange = [now, twoWeeksNext]
    this.getAgents()
    this.getAgencies()
    this.getNationalities()
    this.getHotels()
    this.cancelledOperation = false
    // this.search()
  },
  methods: {
    async search () {
      this.dataTable = await this.getMetricsData(this.agency)
      this.dataTableExplora = await this.getMetricsData(this.exploraAgencies.map(ag => ag.id))
      this.getReservations()
    },
    async setActiveTooltip (id) {
      if (this.activeTooltip === id) {
        this.activeTooltip = ''
        return
      }
      this.loadingTooltip = true
      this.activeTooltip = id
      await this.$apollo.mutate({
        mutation: gql`
          query {
            missingMinDataTraveller(reservationId: ${id}) {
              missingMinDataTraveller
              reservation {
                id confirmationNumber legNumber partyCode traveller { firstName lastName nationality email } hotel { name } checkin checkout agentUsername reservationStatus stepsCompleted isCompletedMin emailTpSent isTravellerProfileOpened isTpLinkCopied hasUnfinishedSteps isReservationWorked
              }
            }
          }`,
        variables: null
      })
        .then(async ({ data }) => {
          this.tooltipContent = data.missingMinDataTraveller.missingMinDataTraveller.join(', ')
          this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === data.missingMinDataTraveller.reservation.id)[0][0]] = data.missingMinDataTraveller.reservation
          this.loadingTooltip = false
        })
    },
    formatedDate (date) {
      return moment(date).locale('es').format('DD/MM/YYYY, HH:mm')
    },
    async getAgents () {
      await this.$apollo.mutate({
        mutation: gql`
          query {
            agents {
              id
              username
            }
          }`,
        variables: null
      })
        .then(async ({ data }) => {
          this.agents = data.agents
        })
    },
    async getAgencies () {
      await this.$apollo.mutate({
        mutation: gql`
          query {
            agencyList {
              id
              name
              isExploraAgency
            }
          }`,
        variables: null
      })
        .then(async ({ data }) => {
          this.agencies = data.agencyList.filter(ag => ag.isExploraAgency === false)
          this.exploraAgencies = data.agencyList.filter(ag => ag.isExploraAgency === true)
        })
    },
    getCellClassName ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 3) {
        return row.isCompletedMin ? '' : 'red-cell'
      }
    },
    async getNationalities () {
      await this.$apollo.mutate({
        mutation: gql`
          query {
            nationalities(lang: "es") {
              value
              description
            }
          }`,
        variables: null
      })
        .then(async ({ data }) => {
          this.nationalities = data.nationalities
        })
    },
    async getMetricsData (agency) {
      this.cancelledOperation = true
      const firstDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
      const lastDate = moment(this.dateRange[1]).format('YYYY-MM-DD')

      const resp = await this.$apollo.mutate({
        mutation: gql`
          query($dateStart: Date!, $dateEnd: Date!, $hotelId: [Int]!, $agentUsername: [String]!, $nationality: String!, $reservationStatus: [String]!, $agency: [Int]!) {
            travellersMetrics (dateStart: $dateStart, dateEnd: $dateEnd, hotelId: $hotelId, agentUsername: $agentUsername, nationality: $nationality, reservationStatus: $reservationStatus, agency: $agency) {
              reservationsCount
              responsePercentage
              minDataPercentage
              emailSentPercentage
              tpOpenedPercentage
              copiedPercentage
              workedPercentage
              webCheckinPassportStepPercentage
              webCheckinSignatureStepPercentage
              webCheckinCountryStepPercentage
              responseTravellerCount
              minDataTravellerCount
              emailSentTravellerCount
              tpOpenedTravellerCount
              copiedTravellerCount
              workedTravellerCount
              webCheckinPassportStepTravellerCount
              webCheckinSignatureStepTravellerCount
              webCheckinCountryStepTravellerCount
            }
          }
        `,
        variables: { dateStart: firstDate, dateEnd: lastDate, hotelId: this.hotel, agentUsername: this.agent, nationality: this.nationality, reservationStatus: this.statusReservation, agency: agency }
      })
        .then(async ({ data }) => {
          this.cancelledOperation = false
          return data.travellersMetrics
        })
      return resp
    },
    getHotels () {
      this.$apollo.mutate({
        mutation: gql`
          query {
            allHotelsList {
              id
              name
            }
          }
        `,
        variables: null
      })
        .then(async ({ data }) => {
          this.hotelList = data.allHotelsList.filter(h => h.id !== '5')
        })
        .catch((err) => {
          console.log({ err })
        })
    },
    timeout (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async getReservations () {
      const firstDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
      const lastDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
      let page = 1
      let ready = false
      this.reservations = []
      this.loading = true
      do {
        await this.$apollo.mutate({
          mutation: gql`query($dateStart: Date!, $dateEnd: Date!, $hotelId: [Int]!, $agentUsername: [String]!, $nationality: String!, $page: Int!, $reservationStatus: [String]!, $agency: [Int]!) {
            reservationsFiltered (dateStart: $dateStart, dateEnd: $dateEnd, hotelId: $hotelId, agentUsername: $agentUsername, nationality: $nationality, page: $page, reservationStatus: $reservationStatus, agency: $agency)  {
              ${this.queryParams}
            }
          }`,
          variables: { page: page, dateStart: firstDate, dateEnd: lastDate, hotelId: this.hotel, agentUsername: this.agent, nationality: this.nationality, reservationStatus: this.statusReservation, agency: this.agency }
        }).then(async ({ data }) => {
          if (this.cancelledOperation) {
            ready = true
            this.cancelledOperation = false
          } else {
            this.reservations = this.reservations.concat(data.reservationsFiltered.objects)
            if (data.reservationsFiltered.hasNext) {
              page += 1
            } else {
              ready = true
              this.loading = false
            }
          }
        })
      } while (ready === false)
    }
  }
}
</script>
<style lang="scss">
.el-loading-mask {
  background-color: #303133 !important;
  display: flex;
  align-items: center;
}
.el-loading-spinner {
  margin-top: 0 !important;
  text-align: center !important;
  position: initial !important;
}
.el-icon-loading:before {
  color: white;
}
#TravellerMetrics {
  .el-button--primary {
    background: #1b1b1b;
    border-color: #1b1b1b;
  }
  .tables {
    display: flex;
  }
  text-align: left;
  padding: 100px;
  .menu-div{
    margin-bottom: 22px;
  }
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid #c4c9d2;
    padding: 8px;
  }
  .flex-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-div {
    margin-bottom: 16px;
  }
  .bold-left {
    border-left: 2px solid;
  }
  .bordered {
    border: 2px solid;
  }
  .tool-tip-label {
    display: flex;
    border-radius: 10px;
    width: 15px;
    background:#121212;
    color: white;
    border: solid 1px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 13px;
    height: 25px;
  }
  .red-cell {
    background: #ff5656;
    color: white;
  }
}
</style>
