<template lang="pug">
#BlockedMsg
  //- Radio
  el-radio(v-if="transportType == 'ARRIVAL'") Aeropuerto de Balmanceda
  el-radio(v-else) {{ $t('lang.transport.airTransferOutPlace') }}
  //- Mensajes
  .pnp-msg(v-if="transportType == 'ARRIVAL'") Aeródromo de Cochrane
  .pnp-msg(v-else) {{ departureTransfer }}
</template>
<script>
import moment from 'moment'

export default {
  props: ['transportType', 'reservation'],
  data () {
    return {
      airTransferIn: 'Usted tiene un vuelo programado el dia <DATE> en horario AM por confirmar desde el aeropuerto de Balmaceda. Una persona del equipo TASR los estará esperando con un identificativo EXPLORA para guiarlos en su embarque.',
      airTransferOut: 'Usted tiene un vuelo programado el día <DATE> en horario por confirmar desde el aeródromo de Cochrane. Nuestro equipo EXPLORA los dejará en la puerta del avion.'
    }
  },
  computed: {
    arrivalTransfer () {
      return this.airTransferIn.replace('<DATE>', moment(this.reservation.checkin).format('DD/MM/YYYY'))
    },
    departureTransfer () {
      return this.airTransferOut.replace('<DATE>', moment(this.reservation.checkout).format('DD/MM/YYYY'))
    }
  }
}
</script>
<style lang="scss">
#BlockedMsg {
  text-align: left;
  padding: 22px;
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
}
</style>
