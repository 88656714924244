import Vue from 'vue'
import Vuex from 'vuex'
import UsersStore from './Users/index.js'
import SimilarTravelerStore from './SimilarTraveler/index.js'
import TravellerProfileStore from './TravellerProfile/index.js'
import MapiStore from './Mapi'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: {
    UsersStore,
    SimilarTravelerStore,
    TravellerProfileStore,
    MapiStore,
  }
})
