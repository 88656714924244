import axios from 'axios'
import settings from '../../../settings'

const urlSociety = settings.societyApi

export const listSimilarTravelers = ({ commit }, { page, filter, checkedout, year, fourWeeksAfter, fourWeeksBefore, hotels }) => {
  const url = `${urlSociety}members/similar_member?page=${page}&filter=${filter}&hotels_codes=${hotels}&include_checkout=${checkedout ? 'True' : 'False'}${year ? '&year_checkedout=' + year : ''}&four_weeks_after=${fourWeeksAfter ? 'True' : 'False'}&four_weeks_before=${fourWeeksBefore ? 'True' : 'False'}`
  return axios.get(url).then(
    response => {
      commit('SET_SIMILAR_TRAVELERS', { allSimilarTravelers: response.data })
      return response.data
    })
    .catch(err => err.response)
}

export const getSimilarTravelerDetail = ({ commit }, { id }) => {
  const url = `${urlSociety}members/similar_member_detail/${id}`
  return axios.get(url).then(
    response => {
      commit('SET_SIMILAR_TRAVELER', { similarTravelerDetail: response.data })
      return response.data
    })
    .catch(err => err.response)
}

export const updateSimilarTravelerDetail = ({ commit }, { id, data }) => {
  const url = `${urlSociety}members/similar_member_detail/${id}`
  return axios.put(url, data).then(
    response => {
      commit('SET_SIMILAR_TRAVELER', { similarTravelerDetail: response.data })
      return response.data
    })
    .catch(err => err.response)
}

export const AddOrUpdateAttachment = ({ commit }, data) => {
  const url = `${process.env.VUE_APP_GRAPHQL_BASE}/operation/reservation_attachment`
  return axios.post(url, data).then(
    response => response,
    error => error
  )
}
