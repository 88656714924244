<template lang="pug">
#HotelForm
  el-form(ref="form", :model="form", :rules="rules")
    .input-label Hotel
    el-form-item(prop="hotel")
      el-select(v-model="form.hotel", filterable, placeholder="Selecciona hotel", style="width: 100%;")
        el-option(v-for="(h, index) in hotels", v-bind:key="index", :label="h.value" :value="h.locationCode")
        el-option(label="Otro" value="OTRO")
      .message(v-if="form.hotel === 'OTRO'", style="padding: 0;") Si el hotel indicado no se encuentra en la zona donde realizamos traslados, nuestro equipo se comunicará con usted.
    div(v-if="form.hotel === 'OTRO'")
      .input-label Por favor, especifique
      el-form-item(prop="otherHotel")
        el-input(v-model="form.otherHotel", placeholder="Nombre del hotel")
    el-card.box-card
      p Un ejecutivo se pondrá en contacto para coordinar el horario
    .button-group
      el-button#explora-btn.primary(@click="checkOtherHotel()") Guardar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['type', 'legNumber', 'isLast', 'addIndex', 'hotel', 'dateTransport', 'confirmationNumber'],
  data () {
    return {
      form: {
        hotel: '',
        otherHotel: ''
      },
      partyApply: [],
      rules: {
        hotel: [{ required: true, message: 'No dejes hotel vacío', trigger: 'blur' }],
        otherHotel: [{ required: true, message: 'No dejes hotel vacío', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['transportsInformation', 'travellerProfile']),
    hotels () {
      return this.transportsInformation.filter(t => t.transportType === 'HOTEL' && t.hotel.id === this.hotel.id)
    }
  },
  methods: {
    ...mapActions(['updateTransport']),
    checkOtherHotel () {
      const haveOtherHotel = this.form.hotel === 'OTRO'
      if (haveOtherHotel) {
        this.$confirm('Si el hotel indicado no se encuentra en la zona donde realizamos traslados, nuestro equipo se comunicará con usted. ¿Desea continuar?', 'Hotel', {
          confirmButtonText: 'ENTENDIDO',
          cancelButtonText: 'CORREGIR',
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.saveDataAndContinue('form')
        }).catch(() => {})
      } else {
        this.saveDataAndContinue('form')
      }
    },
    async saveDataAndContinue (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const confirmationNumbers = [this.confirmationNumber]
          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, 'Yo'))
          this.travellerProfile.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })
          this.travellerProfile.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
                key: res.confirmationNumber
              },
              domProps: { value: reservationCheckVal.value },
              on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          let canContinue = true
          if (parseInt(this.travellerProfile.reservationConsulted.partyCode) === this.confirmationNumber && this.travellerProfile.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: 'Selecciona viajeros',
              message: h('div', null, [
                h('p', null, '¿Quieres asignar tu información de transporte a otros viajeros de tu grupo?'),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }
          if (canContinue) {
            this.$notify({
              title: 'Guardando cambios...',
              message: 'Por favor no cierre el navegador',
              type: 'warning'
            })
            let transportType = ''
            let cityCode = ''

            const transport = this.transportsInformation.find(t => t.locationCode === this.form.hotel && t.hotel.id === this.hotel.id && t.transportType === 'AEROPUERTO')
            if (transport) {
              transportType = transport.transportTypeOws
              cityCode = transport.cityCode
            }
            if (this.form.hotel === 'otro') {
              cityCode = 'OTRO'
            }
            const resp = await this.updateTransport({
              travellerId: this.travellerProfile.id,
              confirmationNumbers: confirmationNumbers,
              transportId: this.form.hotel === 'OTRO' ? 'OTRO' : this.form.hotel,
              transportType: transportType,
              carrierCode: 'HTL',
              locationCode: cityCode,
              otherHotel: this.form.hotel === 'OTRO' ? this.form.otherHotel : null,
              date: this.dateTransport,
              queryType: this.type,
              legNumber: this.legNumber,
              warning: false,
              destinationType: 'HOTEL'
            })
            if (resp) {
              this.$notify({
                title: '¡Guardado!',
                message: 'Los datos han sido actualizados exitósamente',
                type: 'success'
              })
            } else {
              this.$notify({
                title: '¡Error!',
                message: 'Ha sucedido un problema, por favor inténtalo más tarde',
                type: 'error'
              })
            }
          }
        } else {
          return false
        }
      })
    }
  },
  created () {
    let reservation = null
    if (this.legNumber === 1) {
      reservation = this.travellerProfile.reservationConsulted
    } else {
      reservation = this.travellerProfile.aditionalReservations.filter(r => r.legNumber === this.legNumber)[0]
    }
    if (this.type === 'ARRIVAL') {
      if (reservation.arrivalDestinationType === 'HOTEL') {
        this.form.hotel = reservation.arrivalTransportId
        this.form.date = reservation.arrivalTransportDatetime
        this.form.otherHotel = reservation.arrivalOtherHotel
      }
    } else {
      if (reservation.departureDestinationType === 'HOTEL') {
        this.form.hotel = reservation.departureTransportId
        this.form.date = reservation.departureTransportDatetime
        this.form.otherHotel = reservation.departureOtherHotel
      }
    }
  }
}
</script>
<style lang="scss">
#HotelForm {
  .input-label {
    font-size: 12px;
    margin: 16px 0;
  }
  .el-card__body {
    padding: 8px 30px;
    font-style: italic;
    color: #636363;
    border: 1px solid #303133;
  }
  .title {
    display: flex;
  }
  .message {
    line-height: 1;
  }
}
</style>
