import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
import Reservations from '../pages/Reservations.vue'
import Logs from '../components/Logs.vue'
import SimilarTravelersDataTable from '../pages/SimilarTravelersDataTable.vue'
import LogsPage from '../pages/LogsPage.vue'
import TravellerMetrics from '../pages/TravellerMetrics.vue'
import TravellerProfile from '../pages/TravellerProfile.vue'
import MapiReservations from '../pages/MapiReservations.vue'
import UnfinishedSteps from '../pages/UnfinishedSteps.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      routeType: 'public'
    }
  },
  {
    path: '/reservations/:type',
    name: 'ReservationsAgents',
    component: Reservations,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/logs/:idLogs',
    name: 'Logs',
    component: Logs,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: LogsPage,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/similar-travellers',
    name: 'Similitudes',
    component: SimilarTravelersDataTable,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/traveller-metrics',
    name: 'Métricas',
    component: TravellerMetrics,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/mapi-reservations',
    name: 'MAPI',
    component: MapiReservations,
    meta: {
      routeType: 'private'
    }
  },
  {
    path: '/travellerProfile/modifyProfile/:profileToken/confirmationNumber/:confirmationNumber',
    name: 'Editar información viajero',
    component: TravellerProfile
  },
  {
    path: '/unfinished_steps/:reservationId',
    name: 'Pasos inconclusos TP',
    component: UnfinishedSteps
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
