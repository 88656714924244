<template lang="pug">
#DatePicker
  div(style="margin-right: 24px;")
    .input-label-picker Día
    el-select(v-model="day", filterable, placeholder="Selecciona día", style="width: 70px")
      el-option(v-for="(d, index) in daysOfMonth", v-bind:key="index", :label="d.option" :value="d.value")
  div(style="margin-right: 24px;")
    .input-label-picker Mes
    el-select(v-model="month", filterable, placeholder="Selecciona mes", style="width: 120px")
      el-option(v-for="(m, index) in months", v-bind:key="index", :label="m.option" :value="m.value")
  div
    .input-label-picker Año
    el-select(v-model="year", filterable, placeholder="Selecciona mes", style="width: 90px")
      el-option(v-for="(y, index) in years", v-bind:key="index", :label="y" :value="y")
</template>
<script>
export default {
  props: ['value'],
  data () {
    return {
      day: '',
      month: '',
      year: ''
    }
  },
  methods: {
    getDaysInMonth (year, month) {
      return new Date(year, month, 0).getDate()
    },
    getNumberString (number) {
      return number < 10 ? '0' + number : number.toString()
    },
    UpdateDate () {
      // const date = new Date(this.year, this.month - 1, this.day)
      if (this.year !== '' && this.month !== '' && this.day !== '') {
        const date = `${this.year}-${this.month}-${this.day}`
        this.$emit('input', date)
      } else {
        this.$emit('input', null)
      }
    }
  },
  created () {
    if (this.value && this.value !== '') {
      const splitedBirthDate = this.value.split('-')
      this.day = parseInt(splitedBirthDate[2])
      this.month = parseInt(splitedBirthDate[1])
      this.year = parseInt(splitedBirthDate[0])
    }
  },
  watch: {
    month () {
      if (this.getDaysInMonth(this.year, this.month) < this.day) {
        this.day = this.getDaysInMonth(this.year, this.month)
      }
      this.UpdateDate()
    },
    day () {
      this.UpdateDate()
    },
    year () {
      this.UpdateDate()
    }
  },
  computed: {
    daysOfMonth () {
      const days = []
      const totalDays = this.getDaysInMonth(this.year, this.month)
      for (let index = 1; index <= totalDays; index++) {
        days.push({ option: this.getNumberString(index), value: this.getNumberString(index) })
      }
      return days
    },
    months () {
      return [{ option: 'Enero', value: '01' }, { option: 'Febrero', value: '02' }, { option: 'Marzo', value: '03' }, { option: 'Abril', value: '04' },
        { option: 'Mayo', value: '05' }, { option: 'Junio', value: '06' }, { option: 'Julio', value: '07' }, { option: 'Agosto', value: '08' },
        { option: 'Septiembre', value: '09' }, { option: 'Octubre', value: '10' }, { option: 'Noviembre', value: '11' }, { option: 'Diciembre', value: '12' }]
    },
    years () {
      const years = []
      const currentYear = new Date().getFullYear()
      // eslint-disable-next-line
      for (let index = currentYear; index > 1900; index--) {
        years.push(index)
      }
      return years
    }
  }
}
</script>
<style lang="scss">
#DatePicker {
  display: flex;
  .input-label-picker {
    font-size: 12px;
    margin: 0px 0;
  }
}
</style>
