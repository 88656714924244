<template lang="pug">
#DataTable
  h1 {{ title }}
  div(style="margin-right: 22px; display: flex; justify-content: space-between; margin-bottom: 16px;")
    div(style="padding: 16px; border: 1px solid #c8c8c8; border-radius: 12px; margin-bottom: 22px;")
      div(style="display: flex; align-items: center;")
        .circle.black-circle
        label No se ha enviado
      div(style="display: flex; align-items: center;")
        .circle.orange-circle
        label Se ha enviado al titular
    div
      input.search-input(style="width: 300px; margin-right: 8px;", v-on:keyup.enter="sync", v-model="sincyNumber", type="number", placeholder="Ingresa Nº De reserva")
      el-button(type="primary", :disabled="sincyNumber.length === 0", @click="sync", :loading="loading") Sincronizar
  div
    div(style="display: flex; justify-content: left;")
      div(style="display: flex;")
        input.search-input(style="width: 300px;", v-on:keyup.enter="filter(search, hotel, statusReservation, filterWarning, filterHolder, filterSent)", type="text", v-model="search" placeholder="Buscar reserva...")
        el-tooltip(class="item" effect="dark" :content="searchHelpText" placement="top-start")
          el-button.tool-tip-label ?
      div
        label(style="margin: 10px;") Hotel:
        el-select(v-model="hotel", multiple, collapse-tags)
          el-option(v-for="hotel in hotelList" :label="hotel.name", :value="hotel.id")
      div
        label(style="margin: 10px;") Status Reserva:
        el-select(v-model="statusReservation", multiple, style="width: 260px; margin-right: 16px")
          el-option(label="WAITLISTED", value="WAITLISTED")
          el-option(label="INHOUSE", value="INHOUSE")
          el-option(label="RESERVED", value="RESERVED")
      div
        label(style="margin: 10px;") Warning:
        el-select(v-model="filterWarning", collapse-tags)
          el-option(label="Todos", :value="null")
          el-option(label="Con warning", :value="true")
          el-option(label="Sin warning", :value="false")
      div
        label(style="margin: 10px;") Titular:
        el-select(v-model="filterHolder", collapse-tags)
          el-option(label="Todos", :value="null")
          el-option(label="Titulares", :value="true")
          el-option(label="No Titulares", :value="false")
      div
        label(style="margin: 10px;") Enviado:
        el-select(v-model="filterSent", collapse-tags)
          el-option(label="Todos", :value="null")
          el-option(label="Enviado", :value="true")
          el-option(label="No enviado", :value="false")
      div
        el-button(type="primary", @click="filter(search, hotel, statusReservation, filterWarning, filterHolder, filterSent)") Buscar
  div(style="width: 92vw; height: 70vh; overflow: scroll; height: 65vh;", @scroll="onScroll")
    table.explora-table
      thead
        th(@click="sort('checkin')", v-bind:class="{ 'selected-sort': currentSort === 'checkin' || currentSort === '' }")  Check-in
        th(@click="sort('partyCode')", v-bind:class="{ 'selected-sort': currentSort === 'partyCode' || currentSort === '' }") Party
        th(@click="sort('room')", v-bind:class="{ 'selected-sort': currentSort === 'room' || currentSort === '' }")  Hab
        th(@click="sort('traveller')", v-bind:class="{ 'selected-sort': currentSort === 'traveller' }")  Viajero
        th(@click="sort('email')", v-bind:class="{ 'selected-sort': currentSort === 'email' }")  Email
        th(@click="sort('confirmationNumber')", v-bind:class="{ 'selected-sort': currentSort === 'confirmationNumber'}") Nº De reserva
        th(@click="sort('legNumber')", v-bind:class="{ 'selected-sort': currentSort === 'legNumber'}") legNumber
        th(@click="sort('hotel')", v-bind:class="{ 'selected-sort': currentSort === 'hotel' }")  Destino
        th(@click="sort('agentUsername')", v-bind:class="{ 'selected-sort': currentSort === 'agentUsername' }")  Agente
        th(@click="sort('reservationStatus')", v-bind:class="{ 'selected-sort': currentSort === 'reservationStatus' }")  Status reserva
        th(@click="sort('statusTransporte')", v-bind:class="{ 'selected-sort': currentSort === 'statusTransporte' }")  Status trasporte
        th(@click="sort('statusTp')", v-bind:class="{ 'selected-sort': currentSort === 'statusTp' }")  Status TP
        th Warning Transporte
        th(@click="sort('stepsCompleted')", v-bind:class="{ 'selected-sort': currentSort === 'stepsCompleted' }")  Pasos TP
        th(@click="sort('linkTp')", v-bind:class="{ 'selected-sort': currentSort === 'linkTp' }")  Pasaporte
        th(@click="sort('linkTp')", v-bind:class="{ 'selected-sort': currentSort === 'linkTp' }")  Acciones
      tbody
        tr(v-for="(r, index) in sortedReservations" v-bind:key="index")
          td {{ getFormatedDateTime(r.checkin) }}
          td {{ r.partyCode }}
          td {{ r.room }}
          td {{ r.traveller }}
          td {{ r.email }}
          td {{ r.confirmationNumber }}
          td {{ r.legNumber }}
          td {{ r.hotel }}
          td {{ r.agentUsername }}
          td {{ r.reservationStatus }}
          td {{ r.statusTransporte }}
          td {{ r.statusTp }}
          td.green-box(v-bind:class="{'yellow-box': r.warningTransport.length > 0}")
            div(v-if="r.warningTransport.length > 0")
              div Sí ({{ r.warningTransport.join(' y ') }})
              div Revisado: {{ r.revisedWarningCount }}/{{ r.warningTransport.length }}
              el-button.btn-similar(type="primary", @click="revise(r)") Revisar
            div(v-else)
              div No
          td {{ r.stepsCompleted }}
          td
            label(:for="'input-file-' + r.id")
              .el-icon-upload2
            input(type="file" :id="'input-file-' + r.id" @change="handleFileChange($event, r.confirmationNumber, r.tokenProfile)" style="display: none")
          td
            div(v-if="r.blockTpEmail")
              el-tooltip(effect="dark" content="Esta reserva pertenece a una travesía, sólo es posible enviar el T.P. a la reserva con el primer checkin" placement="top")
                el-button.tool-tip-label ?
            div(v-else-if="r.blockTpConnect")
              el-tooltip(effect="dark" content="Esta reserva pertenece a una connect, sólo es posible enviar el T.P. a la reserva con el primer checkin" placement="top")
                el-button.tool-tip-label ?
            div(v-else, style="display: flex; justify-content: space-evenly;")
              el-tooltip(effect="dark" content="Ir a perfil del viajero" placement="top")
                el-button.btn-table(type="primary", @click="goToTp(r.tokenTp, r.confirmationNumber)")
                  .el-icon-user-solid
              el-tooltip(effect="dark" content="Revisar pasos" placement="top")
                el-button.btn-table(type="primary", @click="goToUnfinishedSteps(r.id)")
                  .el-icon-s-order
              el-tooltip(effect="dark" content="Copiar link" placement="top")
                el-button.btn-table(:type="r.isTpLinkCopied ? 'success' : 'primary'", @click="setClipboardText(r.linkTp, r.confirmationNumber, r.legNumber)")
                  .el-icon-document-copy
              el-tooltip(effect="dark" :content="r.emailTpSent ? 'Reenviar correo' : 'Enviar correo'" placement="top")
                el-button.btn-table(:type="r.titularEmailSent || r.emailTpSent ? 'success' : 'primary'", @click="sendByEmail(r.confirmationNumber, r.legNumber)", :loading="loadingBtn")
                  .el-icon-s-promotion(v-if="!loadingBtn")
              el-tooltip(effect="dark" content="Sincronizar pasos" placement="top")
                el-button.btn-table(v-if="r.statusTp === 'Fallido'" type="primary", :loading="loadingBtn", @click="retryFailedSteps(r.confirmationNumber)")
                  .el-icon-refresh(v-if="!loadingBtn")
    div(style="display: flex;", v-if="loadingReservations")
      spinner
      .loading-txt(style="padding-top: 16px; padding-left: 16px;") Cargando reservas...
    reservation-warning-modal(v-model="reviewingWarning", :close="closeModal", :reservation="reviewingReservation", :replaceReservation="replaceReservation")
</template>
<script>
import moment from 'moment'
import gql from 'graphql-tag'
import Spinner from './Spinner'
import ReservationWarningModal from './ReservationWarningModal'
import { mapActions } from 'vuex'

export default {
  props: ['reservations', 'title', 'reload', 'hotelList', 'loadingReservations', 'getReservations', 'filter'],
  components: {
    Spinner,
    ReservationWarningModal
  },
  data () {
    return {
      currentSort: '',
      currentSortDir: 'asc',
      searchHelpText: 'Puedes buscar por: Nº Confirmación, Party Code, viajero, habitación, Status reserva, agente y por fecha de checkin (Ejemplo: 01/01/2020)',
      search: '',
      hotel: [],
      statusReservation: ['RESERVED'],
      mapReservations: [],
      sincyNumber: '',
      loading: false,
      loadingBtn: false,
      reviewingReservation: null,
      reviewingWarning: false,
      filterWarning: null,
      filterHolder: null,
      filterSent: null
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    ...mapActions(['AddOrUpdateAttachment']),
    async handleFileChange(event, confirmationNumber, token) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (this.isFileTypeAllowed(selectedFile)) {
          const fileReader = new FileReader()
          const file = selectedFile;
          fileReader.readAsDataURL(file)
          const dataTicket = new FormData()
          dataTicket.append('reservation_confirmation', confirmationNumber)
          dataTicket.append('token', token)
          dataTicket.append('file_type', 'PASSPORT_FILE')
          dataTicket.append('attachment', file)
          dataTicket.append('is_resident', null)
          this.$notify({
            title: 'Subiendo...',
            message: 'El archivo se está subiendo a nuestra plataforma',
            type: 'info'
          })
          const resp = await this.AddOrUpdateAttachment(dataTicket)
          this.$notify({
            title: resp.status === 200 ? '¡Listo!' : '¡Ups! Hubo un problema',
            message: resp.status === 200 ? 'El archivo se ha subido exitósamente' : 'Algo sucedió, contacta con tu agente de reserva',
            type: resp.status === 200 ? 'success' : 'error'
          })
        } else {
          console.error('Tipo de archivo no permitido. Por favor, elige un archivo PDF, PNG, JPG o JPEG.');
          this.$notify({
            title: 'Por favor, elige un archivo PDF, PNG, JPG o JPEG.',
            message: 'Tipo de archivo no permitido',
            type: 'error'
          })
        }
      }
    },
    isFileTypeAllowed(file) {
      const allowedTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
      return allowedTypes.includes(file.type);
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight && this.loadingReservations === false) {
        console.log('Cargando reservas...')
        this.getReservations()
      }
    },
    closeModal () {
      this.reviewingWarning = false
    },
    loadData () {
      this.mapReservations = [...this.reservations.map(r => {
        return {
          ...r,
          room: r.room === '' || r.room === null ? 'Sin habitación' : r.room,
          hotel: r.hotel.name,
          agentUsername: r.agentUsername == null ? '' : r.agentUsername,
          linkTp: r.linkTravellerProfile,
          tokenTp: r.tokenTravellerProfile,
          tokenProfile: r.traveller[0] ? r.traveller[0].token : '',
          traveller: r.traveller[0] ? r.traveller[0].lastName + ', ' + r.traveller[0].firstName + ' | ' + this.getAge(r.traveller[0].birthday) + ' | ' + (r.traveller[0].nationality !== null ? r.traveller[0].nationality : 'Sin nacionalidad') : '',
          email: r.traveller[0] ? r.traveller[0].email : '',
          termsCovidAccepted: r.termsCovidAccepted === true ? 'Aceptados' : 'No aceptados',
          warningTransport: this.getWarning(r.warningStatusArrival, r.warningStatusDeparture),
          revisedWarningCount: this.getRevisedWarningCount(r.warningStatusArrival, r.warningStatusDeparture)
        }
      })]
    },
    revise (reservation) {
      this.reviewingReservation = reservation
      this.reviewingWarning = true
    },
    getWarning (arrival, departure) {
      const warnings = []
      if (arrival === 'A_2' || arrival === 'A_3') warnings.push('llegada')
      if (departure === 'A_2' || departure === 'A_3') warnings.push('salida')
      return warnings
    },
    getRevisedWarningCount (arrival, departure) {
      let revised = 0
      if (arrival === 'A_3') revised += 1
      if (departure === 'A_3') revised += 1
      return revised
    },
    goToTp (token, confirmationNumber) {
      const routeData = this.$router.resolve({ path: `/travellerProfile/modifyProfile/${token}/confirmationNumber/${confirmationNumber}` })
      window.open(routeData.href, '_blank')
    },
    goToUnfinishedSteps(id) {
      const routeData = this.$router.resolve({ path: `/unfinished_steps/${id}` })
      window.open(routeData.href, '_blank')
    },
    async sendByEmail (confirmationNumber, legNumber) {
      this.loadingBtn = true
      this.syncGroup(confirmationNumber, legNumber)
      await this.$apollo.mutate({
        mutation: gql`
          mutation($confirmationNumber: ID!, $email: String!) {
            SendTravellerProfileLink (confirmationNumber: $confirmationNumber, email: $email) {
              result
              message
            }
          }
        `,
        variables: {
          confirmationNumber: confirmationNumber,
          email: ''
        }
      })
        .then(async ({ data }) => {
          if (data.SendTravellerProfileLink.result) {
            this.$toasted.show(data.SendTravellerProfileLink.message, {
              theme: 'done',
              position: 'top-right',
              duration: 5000
            })
            this.loadingBtn = false
            const reservation = this.reservations.find(res => res.confirmationNumber === confirmationNumber)

            // eslint-disable-next-line vue/no-mutating-props
            if (reservation) this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === reservation.id)[0][0]].emailTpSent = true

            if (reservation && reservation.confirmationNumber.toString() === reservation.partyCode) {
              const party = this.reservations.filter(res => res.partyCode === reservation.confirmationNumber.toString())
              party.forEach(res => {
                // eslint-disable-next-line vue/no-mutating-props
                this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === res.id)[0][0]].emailTpSent = true
              })
            }
            this.loadData()
          } else {
            this.$toasted.show(data.SendTravellerProfileLink.message, {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000
            })
            this.loadingBtn = false
          }
        })
    },
    async retryFailedSteps (confirmationNumber) {
      this.loadingBtn = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation($confirmationNumber: ID!) {
            RetryFailedSteps(confirmationNumber: $confirmationNumber) {
              result
              message
            }
          }
        `,
        variables: {
          confirmationNumber: confirmationNumber
        }
      })
        .then(async ({ data }) => {
          if (data.RetryFailedSteps.result) {
            this.$toasted.show(data.RetryFailedSteps.message, {
              theme: 'done',
              position: 'top-right',
              duration: 5000
            })
            this.loadingBtn = false
            this.reload()
          } else {
            this.$toasted.show(data.RetryFailedSteps.message, {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000
            })
            this.loadingBtn = false
          }
        })
    },
    getAge (birth) {
      var today = new Date()
      var birthDate = new Date(birth)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    },
    async sync () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation($confirmationNumber: Int!) {
            OperaSynchronizeById(confirmationNumber: $confirmationNumber, legNumber: 1){
              result
            }
          }
        `,
        variables: {
          confirmationNumber: this.sincyNumber
        }
      })
        .then(async ({ data }) => {
          this.loading = false
          this.search = this.sincyNumber
          this.filter(this.search, this.hotel, ['RESERVED'])
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    sort (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = s
    },
    getFormatedDateTime (date) {
      return moment(date).locale('es').format('DD/MM/YYYY, HH:mm')
    },
    async syncGroup (confirmationNumber, legNumber, isCopying = false, notify = false) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation($confirmationNumber: Int!, $legNumber: Int!, $isCopying: Boolean!) {
            SyncGroup(confirmationNumber: $confirmationNumber, legNumber: $legNumber, isCopying: $isCopying){
              result
              reservation { id confirmationNumber legNumber partyCode titularEmailSent emailTpSent isTpLinkCopied hasUnfinishedSteps traveller { firstName lastName nationality birthday email token } room hotel { name } linkTravellerProfile tokenTravellerProfile checkin statusTransporte agentUsername reservationStatus statusTp stepsCompleted termsCovidAccepted  warningStatusArrival warningStatusDeparture arrivalTransportDatetime departureTransportDatetime }
            }
          }
        `,
        variables: {
          confirmationNumber: confirmationNumber,
          legNumber: legNumber,
          isCopying: isCopying
        }
      })
        .then(async ({ data }) => {
          this.replaceReservation(data.SyncGroup.reservation)
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    replaceReservation (reservation) {
      // eslint-disable-next-line vue/no-mutating-props
      this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === reservation.id)[0][0]] = reservation
      this.loadData()
    },
    async setClipboardText (text, confirmationNumber, legNumber) {
      var id = 'el-id-del-textarea'
      var existsTextarea = document.getElementById(id)

      if (!existsTextarea) {
        console.log('Creando textarea')
        var textarea = document.createElement('textarea')
        textarea.id = id
        // Coloca el textarea en el borde superior izquierdo
        textarea.style.position = 'fixed'
        textarea.style.top = 0
        textarea.style.left = 0

        // Asegurate que las dimensiones del textarea son minimas, normalmente 1px
        // 1em no funciona porque esto generate valores negativos en algunos exploradores
        textarea.style.width = '1px'
        textarea.style.height = '1px'

        // No se necesita el padding
        textarea.style.padding = 0

        // Limpiar bordes
        textarea.style.border = 'none'
        textarea.style.outline = 'none'
        textarea.style.boxShadow = 'none'

        // Evitar el flasheo de la caja blanca al renderizar
        textarea.style.background = 'transparent'
        document.querySelector('body').appendChild(textarea)
        console.log('The textarea now exists :)')
        existsTextarea = document.getElementById(id)
      } else {
        console.log('El textarea ya existe')
      }

      existsTextarea.value = text
      existsTextarea.select()
      try {
        var status = document.execCommand('copy')
        if (!status) {
          this.$toasted.show('No se ha copiado el link', {
            theme: 'bubble',
            position: 'top-right',
            duration: 5000
          })
        } else {
          const reservation = this.reservations.find(res => res.confirmationNumber === confirmationNumber)

          // eslint-disable-next-line vue/no-mutating-props
          if (reservation) this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === reservation.id)[0][0]].isTpLinkCopied = true

          if (reservation && reservation.confirmationNumber.toString() === reservation.partyCode) {
            const party = this.reservations.filter(res => res.partyCode === reservation.confirmationNumber.toString())
            party.forEach(res => {
              // eslint-disable-next-line vue/no-mutating-props
              this.reservations[this.reservations.map((x, i) => [i, x]).filter(x => x[1].id === res.id)[0][0]].isTpLinkCopied = true
            })
          }
          this.loadData()
          this.$toasted.show('Link Copiado correctamente', {
            theme: 'done',
            position: 'top-right',
            duration: 5000
          })
        }
      } catch (err) {
        this.$toasted.show('Error al copiar el link', {
          theme: 'bubble',
          position: 'top-right',
          duration: 5000
        })
      }

      await this.syncGroup(confirmationNumber, legNumber, true)
    }
  },
  computed: {
    sortedReservations () {
      // eslint-disable-next-line
      return this.mapReservations.sort((a, b) => {
        if (this.currentSort === '') {
          return a.checkin - b.checkin || a.partyCode - b.partyCode || a.romm - b.room
        } else {
          let modifier = 1
          if (this.currentSortDir === 'desc') modifier = -1
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
          return 0
        }
      })
    }
  },
  watch: {
    reservations () {
      this.loadData()
    },
    hotelList () {
      this.hotel = this.hotelList.map(h => h.id)
    }
  }
}
</script>
<style lang="scss">
.el-tag.el-tag--info {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    padding: 0px !important;
    font-size: 9px;
    height: 23px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
#DataTable {
  padding-top: 120px;
  padding-left: 90px;
  text-align: left;
  th {
    cursor:pointer;
  }
  #doublescroll { overflow: hidden; width:150px }
  #doublescroll { margin: 0; padding: 1em; white-space: nowrap; }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .black-circle, .el-button--primary {
    background: #1b1b1b;
  }
  .yellow-box {
    background: #f7d053 !important;
  }
  .green-box {
    background: #71d65d;
  }
  .el-button--primary {
    border-color: #1b1b1b;
  }
  .el-button--success {
    border-color: #f1a33c;
  }
  .orange-circle, .el-button--success {
    background: #f1a33c;
  }
  .search-input {
    width: 250px;
    margin-bottom: 20px;
    padding: 8px;
  }
  .tool-tip-label {
    border-radius: 50%;
    width: 20px;
    background:white;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    height: 38px;
  }
  .explora-table {
    text-align: center;
    width: 100%;
    tr:nth-child(even) {background-color: #f7f7f7;}
    th, td {
      padding: 4px;
    }
    th {
      color: #1b1b1b;
      white-space: nowrap;
      border-bottom: 1px solid #ddd;
    }
  }
  h1 {
    text-align: left;
    margin-bottom: 24px;
    margin-top: -34px;
  }
  .selected-sort {
    font-weight: bold;
    color: rgb(83, 84, 85) !important;
  }
  .btn-table {
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    border-radius: 50%;
  }
}
.toasted.done {
    background-color: #71d65d !important;
    color: white;
    padding: 8px;
    border-radius: 13px
}
</style>
