<template lang="pug">
#ReservationsAgents
  reservations-data-table(v-if="reservationsData", :hotelList="hotelList" :reservations="reservationsData", :title="title", :getReservations="getReservations", :loadingReservations="loading", :reload="reload", :filter="filter")
</template>
<script>
import { mapGetters } from 'vuex'
import ReservationsDataTable from '../components/ReservationsDataTable.vue'
import gql from 'graphql-tag'

export default {
  components: {
    ReservationsDataTable
  },
  data () {
    return {
      reservationsData: [],
      hotelList: [],
      search: '',
      hotel: [],
      statusReservation: ['RESERVED'],
      loading: true,
      page: 1,
      noDataNext: false,
      filterWarning: null,
      filterHolder: null,
      filterSend: null,
      queryParams: 'page pages hasNext hasPrev objects { id confirmationNumber legNumber partyCode titularEmailSent emailTpSent blockTpEmail blockTpConnect isTpLinkCopied hasUnfinishedSteps traveller { firstName lastName nationality birthday email token } room hotel { name } linkTravellerProfile tokenTravellerProfile checkin statusTransporte agentUsername reservationStatus statusTp stepsCompleted termsAccepted termsCovidAccepted  warningStatusArrival warningStatusDeparture arrivalTransportDatetime arrivalTransportId departureTransportDatetime arrivalOtherHotel departureTransportId departureOtherHotel}'
    }
  },
  computed: {
    ...mapGetters(['user']),
    title () {
      if (this.$route.params.type === 'agentReservations') {
        return 'Mis reservas'
      }
      if (this.$route.params.type === 'allFutureReservations') {
        return 'Todas las reservas'
      }
      if (this.$route.params.type === 'failedReservations') {
        return 'Reservas fallidas'
      }
      return 'Reservas guardado inconcluso'
    }
  },
  methods: {
    filter (search, hotel, statusReservation, filterWarning, filterHolder, filterSend) {
      this.search = search
      this.hotel = hotel
      this.statusReservation = statusReservation
      this.filterWarning = filterWarning
      this.filterHolder = filterHolder
      this.filterSend = filterSend
      this.reload()
    },
    async reload () {
      this.reservationsData = []
      this.page = 1
      this.noDataNext = false
      await this.getReservations()
      this.loading = false
    },
    async getReservations () {
      let queryUsername = ''

      if (this.$route.params.type === 'agentReservations') {
        queryUsername = 'agentUsername: $username, '
      }
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
          query${this.$route.params.type === 'agentReservations' ? '($username: String!, $statusReservations: [String], $hotel: [ID], $warning: Boolean, $holder: Boolean, $send: Boolean) ' : '($statusReservations: [String], $hotel: [ID], $warning: Boolean, $holder: Boolean, $send: Boolean)'} {
            ${this.$route.params.type} (
            ${queryUsername}
            page: ${this.page},
            filter: {
              search: "${this.search}",
              hotel: $hotel,
              statusReservations: $statusReservations,
              warning: $warning,
              holder: $holder,
              send: $send
            }){
              ${this.queryParams}
            }
          }
        `,
        variables: {
          username: this.user.userName,
          statusReservations: this.statusReservation,
          hotel: this.hotel,
          warning: this.filterWarning,
          holder: this.filterHolder,
          send: this.filterSend
        }
      })
        .then(async ({ data }) => {
          let newdata = null
          if (this.$route.params.type === 'agentReservations') {
            newdata = data.agentReservations
          }
          if (this.$route.params.type === 'allFutureReservations') {
            newdata = data.allFutureReservations
          }
          if (this.$route.params.type === 'failedReservations') {
            newdata = data.failedReservations
          }
          if (this.$route.params.type === 'failedReservationsPgo') {
            newdata = data.failedReservationsPgo
          }
          if (newdata) {
            if (newdata.hasNext) {
              this.page += 1
            }
            newdata.objects.forEach(res => {
              const dataExist = this.reservationsData.filter(reserva => reserva.id === res.id).length > 0
              if (!dataExist) {
                this.reservationsData.push(res)
              }
            })
          } else {
            this.noDataNext = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.loading = false
    },
    getHotels () {
      this.$apollo.mutate({
        mutation: gql`
          query {
            allHotelsList {
              id
              name
            }
          }
        `,
        variables: null
      })
        .then(async ({ data }) => {
          this.hotelList = data.allHotelsList.filter(h => h.id !== '5')
        })
        .catch((err) => {
          console.log({ err })
        })
    }
  },
  created () {
    this.getHotels()
    this.getReservations()
  }
  // watch: {
  //   $route (to, from) {
  //     console.log('jhahjaha')
  //   }
  // }
}
</script>
<style lang="scss">
#ReservationsAgents {
  .content {
    margin-top: 88px;
    width: 100%;
  }
}
</style>
