<template lang="pug">
#Logs
  div(style="display: flex;")
    el-input(v-model="search", placeholder="Busca por confirmation number o nombre del viajero", clearable, @change="getReport(1)")
    el-button(@click="getReport(1)") Buscar
  el-table(:data="logs.objects", style="width: 100%", v-loading="loading", max-height="700")
    el-table-column(prop="reservation", label="Reserva")
      template(slot-scope="scope")
        span {{ scope.row.reservation.confirmationNumber }}
    el-table-column(prop="reservation", label="Viajero")
      template(slot-scope="scope")
        span {{ scope.row.reservation.traveller[0].firstName + ' ' + scope.row.reservation.traveller[0].lastName }}
    el-table-column(prop="date", label="Última actualización")
      template(slot-scope="scope")
        span {{ getFormatedDateTime(scope.row.date) }}
    el-table-column(prop="id", label="Acciones")
      template(slot-scope="scope")
        el-button(type="primary", @click="showLogs(scope.row.id)") Ver Logs
  el-pagination(v-if="logs", :page-size="50", layout="prev, pager, next", :total="logs.count", @current-change="getReport")
</template>
<script>
import moment from 'moment'
import gql from 'graphql-tag'

export default {
  data () {
    return {
      logs: [],
      search: '',
      loading: false
    }
  },
  methods: {
    getFormatedDateTime (date) {
      return moment(date).locale('es').format('DD/MM/YYYY, HH:mm')
    },
    showLogs (id) {
      const routeData = this.$router.resolve({ path: '/logs/' + id })
      window.open(routeData.href, '_blank')
      // this.$router.push('/logs/' + id)
    },
    async getReport (page) {
      this.loading = true
      const resp = await this.$apollo.mutate({
        mutation: gql`query ($page: Int, $search: String) {
          logsTravellerProfile (page: $page, search: $search) {
            page
              pages
              count
              hasNext
              objects {
                id
                date
                reservation {
                  confirmationNumber
                  traveller {
                    firstName
                    lastName
                  }
                }
              }
            }
          }`,
        variables: {
          page: page,
          search: this.search
        }
      })
      this.logs = resp.data.logsTravellerProfile
      this.loading = false
    }
  },
  async created () {
    this.getReport(1)
  }
}
</script>
<style lang="scss">
#Logs {
  padding: 100px;
}
</style>
