<template lang="pug">
#MapiCalendar.flex-col.gap-2
  el-form(
    @submit.native.prevent="submitSearch"
  )
    el-input(
      v-model="search"
      placeholder="Buscar reserva"
      clearable prefix-icon="el-icon-search"
    )
  v-calendar.custom-calendar.max-w-full(
    ref="calendar"
    :attributes="attributes"
    @update:from-page=""
    disable-page-swipe
  )
    template(#day-content="{ day, attributes }")
      .flex.flex-col.h-full.z-10.overflow-hidden.relative(
        :class="{ highlight: moment(highlightedDate).isSame(day.date, 'day') }"
      )
        span.day-label.text-sm.text-gray-900 {{ day.day }}
        .flex-grow.overflow-y-auto.overflow-x-auto.flexgap
          .flex-col.text-xs.leading-tight.rounded-sm.p-1.mt-0(
            v-for="attr in attributes"
            :key="attr.key"
            :class="attr.customData.class"
          )
            .flex.justify-space-between
              span {{ attr.customData.r.confirmationNumber }}
              .check-icons
                el-tooltip(effect="dark" :content="`Ticket parque ${attr.customData.r.mapiVisit.hasTicketParque ? 'comprado' : 'sin comprar'}`" placement="top")
                  .el-icon-check(:class="{ 'green': attr.customData.r.mapiVisit.hasTicketParque }")
                el-tooltip(effect="dark" :content="`Ticket tren ${attr.customData.r.mapiVisit.hasTicketTren ? 'comprado' : 'sin comprar'}`" placement="top")
                  .el-icon-check(:class="{ 'green': attr.customData.r.mapiVisit.hasTicketTren }")
                el-tooltip(effect="dark" :content="`Ticket bus ${attr.customData.r.mapiVisit.hasTicketBus ? 'comprado' : 'sin comprar'}`" placement="top")
                  .el-icon-check(:class="{ 'green': attr.customData.r.mapiVisit.hasTicketBus }")
            span {{ attr.customData.r.travellerObj.firstName }} {{ attr.customData.r.travellerObj.lastName }}

  CalendarSearchModal(
    v-model="searchResultsModal"
    :search="search"
    :items="attributes"
    @selectResult="jumpToItem"
  )
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import CalendarSearchModal from './CalendarSearchModal.vue'
import { Tooltip, Form } from 'element-ui'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    'v-calendar': Calendar,
    [Tooltip.name]: Tooltip,
    [Form.name]: Form,
    CalendarSearchModal,
  },

  data() {
    return {
      attributes: [],
      search: null,
      searchResultsModal: false,
      highlightedDate: null,
    }
  },

  computed: {
    moment: () => moment,
  },

  methods: {
    ...mapActions(['futureReservationsMapi', 'setApprovedMapi']),

    async fetch() {
      let page = 1
      let hasNext = false
      this.isFetching = true
      do {
        const res = await this.futureReservationsMapi({
          page: page,
          weeks: 52,
          hotelId: 4,
          forTicketsMode: false,
          aditionalFields: `criticalFieldsNotCompleted tokenTravellerProfile`,
        })

        const reservations = res.objects
          // .filter((r) => r.mapiVisit)
          .map((r) => {
            const date =
              r.mapiVisit?.visitDate ||
              r.mapiVisit?.preferredDate ||
              moment(r.checkout).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss')

            if (!r.mapiVisit) {
              r.mapiVisit = {
                hasTicketParque: false,
                hasTicketTren: false,
                hasTicketBus: false,
              }
            }
            return {
              key: r.id,
              dates: new Date(date),
              customData: {
                r: {
                  ...r,
                },
                class: 'bg-blue-500 text-white',
                searchString:
                  r.confirmationNumber +
                  r.partyCode +
                  r.travellerObj.firstName +
                  r.travellerObj.lastName,
              },
            }
          })
        this.attributes = [...this.attributes, ...reservations]

        page += 1
        hasNext = res.hasNext
      } while (hasNext)
      this.isFetching = false
    },

    async submitSearch() {
      console.log('buscar')
      this.searchResultsModal = true
    },

    async jumpToItem(item) {
      this.searchResultsModal = false
      await this.$refs.calendar.move(item.date)

      setTimeout(() => {
        this.highlightedDate = item.date
      }, 80)
      setTimeout(() => {
        this.highlightedDate = null
      }, 580)
    },
  },

  created() {
    this.fetch()
  },
}
</script>

<style lang="scss" scoped>
@import './CustomCalendar.scss';

.check-icons {
  color: #cccccc;

  > div {
    opacity: 0.3;
    padding: 1px;
  }

  .green {
    border-radius: 50%;
    background-color: #4caf50;
    color: #fff;
    opacity: 1;
  }
}

.highlight {
  animation: highlight 0.5s ease-in;
  outline: 0;
  transform: scale(1);
  z-index: 1;
}

.relative {
  position: relative;
}

@keyframes highlight {
  10%,
  90% {
    outline: 1px solid #3265f0;
    background-color: #b8ddff;
    transform: scale(1.02);
  }
  50% {
    outline: 2px solid #3265f0;
    background-color: #b8ddff;
    transform: scale(1.04);
  }
}
</style>
