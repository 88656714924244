<template lang="pug">
#TravellerRequirements
  h2 Preferencias y restricciones alimentarias
  .label-form Por favor seleccione las alternativas
  .input-form
    el-checkbox(v-model="dontNeedFoodReq") No tengo preferencias o restricciones alimentarias
    el-checkbox.req(v-for="fr in travellerProfile.foodRequirements" :label="fr.name", :disabled="dontNeedFoodReq" v-bind:key="fr.id", v-model="fr.haveRelation")
  span(v-if="travellerProfile.foodRequirements.filter(fr =>  fr.code === 'OTHER' && fr.haveRelation).length > 0")
    .input-form Otras restricciones alimentarias
    el-input(type="textarea", :autosize="{ minRows: 2}", v-model="travellerProfile.foodRestrictions")
  h2 Alergias
  .label-form Alimentarias
  el-select(v-model="foodAllergiesValue", :disabled="dontNeedFoodReq", default-first-option, multiple, filterable, remote, reserve-keyword, allow-create, size="large", placeholder="Ingrese)")
    el-option(v-for="tag in foodAlergyTags", v-bind:key="tag.id", :value="tag.value", :label="tag.value")
  .label-form Otro
  el-select(v-model="medicalAllergiesValue", default-first-option,:disabled="dontNeedMedReq", multiple, filterable, remote, reserve-keyword, allow-create, size="large", placeholder="Ingrese")
  h2 Condiciones médicas
  .label-form Por favor seleccione las alternativas
  .input-form
    el-checkbox(v-model="dontNeedMedReq") No tengo ninguna condición medica
    el-checkbox.req(v-for="mc in travellerProfile.medicalConditions", :disabled="dontNeedMedReq", :label="mc.name" v-bind:key="mc.id", v-model="mc.haveRelation")
    el-option(v-for="tag in medicalAlergyTags", v-bind:key="tag.id", :value="tag.value", :label="tag.value")
  span(v-if="travellerProfile.medicalConditions.filter(mc =>  mc.code === 'OTHER' && mc.haveRelation).length > 0")
    .input-form Otras restricciones médicas
    el-input(type="textarea", :autosize="{ minRows: 2}", v-model="travellerProfile.otherMedicalConditions")
  .label-form Enfermedades
  el-select(v-model="medicalDiseasesValue", default-first-option, :disabled="dontNeedMedReq", multiple, filterable, remote, reserve-keyword, allow-create, size="large", placeholder="Ingrese")
    el-option(v-for="tag in medicalDiseasesTags", v-bind:key="tag.id", :value="tag.value", :label="tag.value")
  .label-form Lesiones
  el-select(v-model="medicalInjuriesValue", default-first-option, multiple, :disabled="dontNeedMedReq", filterable, remote, reserve-keyword, allow-create, size="large", placeholder="Ingrese")
    el-option(v-for="tag in medicalInjuriesTags", v-bind:key="tag.id", :value="tag.value", :label="tag.value")
  .label-form Cirugías
  el-select(v-model="medicalSurgiesValue", default-first-option, multiple, :disabled="dontNeedMedReq", filterable, remote, reserve-keyword, allow-create, size="large", placeholder="Ingrese")
    el-option(v-for="tag in medicalSurgiesTags", v-bind:key="tag.id", :value="tag.value", :label="tag.value")
  .button-group
    el-button#explora-btn.primary(@click="saveData") GUARDAR
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      foodAllergiesValue: [],
      medicalAllergiesValue: [],
      medicalDiseasesValue: [],
      medicalInjuriesValue: [],
      medicalSurgiesValue: [],
      dontNeedFoodReq: false,
      dontNeedMedReq: false
    }
  },
  created () {
    this.foodAllergiesValue = this.travellerProfile.tags.filter(tag => tag.category === 'FOOD' && tag.subCategory === 'ALLERGY').map(tag => tag.value)
    this.medicalAllergiesValue = this.travellerProfile.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'MED_ALLERGY').map(tag => tag.value)
    this.medicalDiseasesValue = this.travellerProfile.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'DISEASE').map(tag => tag.value)
    this.medicalInjuriesValue = this.travellerProfile.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'INJURY').map(tag => tag.value)
    this.medicalSurgiesValue = this.travellerProfile.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'SURGERY').map(tag => tag.value)
    if (this.travellerProfile.hasFoodReq === false) this.dontNeedFoodReq = true
    if (this.travellerProfile.hasMedicalReq === false) this.dontNeedFoodReq = true
  },
  methods: {
    ...mapActions(['saveTravellerRequirements']),
    async saveData () {
      if ((this.dontNeedFoodReq === false && this.travellerProfile.foodRequirements.filter(fr => fr.haveRelation).length === 0 &&
      this.foodAllergiesValue.length === 0 && this.medicalAllergiesValue.length === 0)) {
        this.$notify({
          title: 'Formulario alimentación',
          message: 'Si no tienes restricciones alimentarias, debes declararlo',
          type: 'error'
        })
      } else if ((this.dontNeedMedReq === false && this.travellerProfile.medicalConditions.filter(mc => mc.haveRelation).length === 0 &&
      this.medicalDiseasesValue.length === 0 && this.medicalInjuriesValue.length === 0 && this.medicalSurgiesValue.length === 0)) {
        this.$notify({
          title: 'Formulario médico',
          message: 'Si no tienes restricciones médicas, debes declararlo',
          type: 'error'
        })
      } else {
        this.$notify({
          title: 'Guardando cambios...',
          message: 'Por favor no cierre el navegador',
          type: 'warning'
        })
        let foodAllergies = this.foodAllergiesValue.map(fa => ({ value: fa }))
        let foodRequirements = this.travellerProfile.foodRequirements.map(fr => ({ id: fr.id, name: fr.name, haveRelation: fr.haveRelation }))

        if (this.dontNeedFoodReq) {
          foodAllergies = []
          foodRequirements = this.travellerProfile.foodRequirements.map(fr => ({ id: fr.id, name: fr.name, haveRelation: false }))
        }

        let medicalDiseases = this.medicalDiseasesValue.map(md => ({ value: md }))
        let medicalInjuries = this.medicalInjuriesValue.map(mi => ({ value: mi }))
        let medicalSurgies = this.medicalSurgiesValue.map(ms => ({ value: ms }))
        let medicalAllergies = this.medicalAllergiesValue.map(ma => ({ value: ma }))
        let medicalConditions = this.travellerProfile.medicalConditions.map(ma => ({ id: ma.id, name: ma.name, haveRelation: ma.haveRelation }))

        if (this.dontNeedMedReq) {
          medicalDiseases = []
          medicalInjuries = []
          medicalSurgies = []
          medicalAllergies = []
          medicalConditions = this.travellerProfile.medicalConditions.map(ma => ({ id: ma.id, name: ma.name, haveRelation: false }))
        }
        const resp = await this.saveTravellerRequirements({
          id: this.travellerProfile.id,
          hasFoodReq: !this.dontNeedFoodReq,
          hasMedicalReq: !this.dontNeedMedReq,
          foodRequirements: foodRequirements,
          medicalConditions: medicalConditions,
          foodAllergiesTags: foodAllergies,
          medicalDiseaseTags: medicalDiseases,
          medicalInjuryTags: medicalInjuries,
          medicalSurgeryTags: medicalSurgies,
          medicalAllergiesTags: medicalAllergies,
          confirmationNumber: this.travellerProfile.reservationConsulted.confirmationNumber,
          foodRestrictions: this.travellerProfile.foodRestrictions || '',
          otherMedicalConditions: this.travellerProfile.otherMedicalConditions || ''
        })
        if (resp) {
          this.$notify({
            title: '¡Guardado!',
            message: 'Los datos han sido actualizados exitósamente',
            type: 'success'
          })
        } else {
          this.$notify({
            title: '¡Error!',
            message: 'Ha sucedido un problema, por favor inténtalo más tarde',
            type: 'error'
          })
        }
      }
    }
  },
  watch: {
    dontNeedFoodReq () {
      this.travellerProfile.foodRequirements.forEach(el => {
        el.haveRelation = false
      })
      this.travellerProfile.foodRestrictions = ''
    },
    dontNeedMedReq () {
      this.travellerProfile.medicalConditions.forEach(el => {
        el.haveRelation = false
      })
      this.travellerProfile.otherMedicalConditions = ''
    }
  },
  computed: {
    ...mapGetters(['travellerProfile', 'tagsConditions']),
    foodAlergyTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'FOOD' && tag.subCategory === 'ALLERGY')
      } else {
        return []
      }
    },
    medicalAlergyTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'MED_ALLERGY')
      } else {
        return []
      }
    },
    medicalDiseasesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'DISEASE')
      } else {
        return []
      }
    },
    medicalInjuriesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'INJURY')
      } else {
        return []
      }
    },
    medicalSurgiesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'SURGERY')
      } else {
        return []
      }
    }
  }
}
</script>
<style lang="scss">
#TravellerRequirements {
  padding: 8px 16px;
  text-align: left;
  .input-form {
    margin-top: 10px;
    display: inline-grid;
  }
  .label-form {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .el-select {
    width: 100%;
    margin-bottom: 24px;
  }
  .req {
    width: 40%;
    margin-bottom: 7px;
  }
  .el-checkbox {
    margin-bottom: 6px;
  }
}
</style>
