<template lang="pug">
el-dialog(
  title="Resultados de la búsqueda"
  :visible="value"
  @update:visible="$emit('input', $event)"
  custom-class="dialog"
)
  el-table(:data="results" @row-click="$emit('selectResult', $event)")
    el-table-column(prop="day" label="Día" width="60px")
    el-table-column(prop="month" label="Mes" width="60px")
    el-table-column(prop="year" label="Año")
    el-table-column(prop="confirmationNumber" label="Reserva")
    el-table-column(prop="travellerName" label="Nombre")
</template>

<script>
import { Dialog, Table, TableColumn } from 'element-ui'
import moment from 'moment'

export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    results() {
      if (!this.items || !this.search) return []
      const search = this.search.toLowerCase()
      return this.items
        .filter((item) =>
          item.customData.searchString.toLowerCase().includes(search)
        )
        .map((item) => ({
          key: item.key,
          date: item.dates,
          day: moment(item.dates).format('DD'),
          month: moment(item.dates).format('MMM'),
          year: moment(item.dates).format('YYYY'),
          confirmationNumber: item.customData.r.confirmationNumber,
          travellerName: `${item.customData.r.travellerObj.firstName || ''} ${
            item.customData.r.travellerObj.lastName || ''
          }`,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dialog {
  margin-top: 60px !important;
  margin: 0 0 50px auto;
  text-align: left;
}

::v-deep .el-table.el-table--enable-row-hover table.el-table__body tr:hover > td {
  background: #F5F7FA;
}
</style>
