<template lang="pug">
#Warning(v-if="reservation")
  el-dialog(:visible.sync='modalOpened' width='420px')
    div(v-if="reservation.warningTransport.includes('llegada')")
      h3 Warning de llegada
      span(v-if="reservation.arrivalTransportId == 'OTRO'") El viajero ha registrado una llegada en el hotel #[strong {{ reservation.arrivalOtherHotel }}]
      span(v-else) El viajero ha registrado una llegada el #[strong {{ getFormatedDateTime(reservation.arrivalTransportDatetime) }}]
      .center-btn
        el-button(type='primary' @click="markAsReviewed('ARRIVAL')", :disabled="revisedArrival", :loading="loadingBtns['ARRIVAL']", v-bind:class="{'orange-btn' : revisedArrival }") {{ revisedArrival ? 'Revisado ✓' : 'Marcar como revisado' }}
    hr(v-if="reservation.warningTransport.includes('llegada') && reservation.warningTransport.includes('salida')")
    div(v-if="reservation.warningTransport.includes('salida')")
      h3 Warning de salida
      span(v-if="reservation.departureTransportId == 'OTRO'")  El viajero ha registrado una salida en el hotel #[strong {{ reservation.departureOtherHotel }}]
      span(v-else) El viajero ha registrado una salida el #[strong {{ getFormatedDateTime(reservation.departureTransportDatetime) }}]
      .center-btn
        el-button(type='primary' @click="markAsReviewed('DEPARTURE')", :disabled="revisedDeparture", :loading="loadingBtns['DEPARTURE']", v-bind:class="{'orange-btn' : revisedDeparture }") {{ revisedDeparture ? 'Revisado ✓' : 'Marcar como revisado' }}
    span.dialog-footer(slot='footer')
      el-button(type='secondary' @click='close()') Cerrar
</template>
<script>
import moment from 'moment'
import gql from 'graphql-tag'

export default {
  props: ['value', 'close', 'reservation', 'replaceReservation'],
  data () {
    return {
      loadingBtns: {
        ARRIVAL: false,
        DEPARTURE: false
      }
    }
  },
  methods: {
    getFormatedDateTime (date) {
      return moment(date).locale('es').format('DD/MM/YYYY, HH:mm')
    },
    async markAsReviewed (transportType) {
      this.loadingBtns[transportType] = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation($reservationId: ID!, $transportType: String!) {
            MarkWarningAsReviewed (reservationId: $reservationId, transportType: $transportType) {
              result
              message
              reservation { id confirmationNumber legNumber partyCode titularEmailSent emailTpSent isTpLinkCopied hasUnfinishedSteps traveller { firstName lastName nationality birthday email } room hotel { name } linkTravellerProfile tokenTravellerProfile checkin statusTransporte agentUsername reservationStatus statusTp stepsCompleted termsAccepted termsCovidAccepted  warningStatusArrival warningStatusDeparture arrivalTransportDatetime departureTransportDatetime }
            }
          }
        `,
        variables: {
          reservationId: this.reservation.id,
          transportType
        }
      })
        .then(({ data }) => {
          const response = data.MarkWarningAsReviewed
          this.$toasted.show(response.message, {
            theme: response.result === true ? 'done' : 'bubble',
            position: 'top-right',
            duration: 5000
          })
          if (response.result === true) {
            this.replaceReservation(response.reservation)
            if (transportType === 'ARRIVAL') {
              // eslint-disable-next-line vue/no-mutating-props
              this.reservation.warningStatusArrival = 'A_3'
            } else if (transportType === 'DEPARTURE') {
              // eslint-disable-next-line vue/no-mutating-props
              this.reservation.warningStatusDeparture = 'A_3'
            }
          }
          this.loadingBtns[transportType] = false
        })
    }
  },
  computed: {
    revisedArrival () {
      if (this.reservation) return this.reservation.warningStatusArrival === 'A_3'
      return false
    },
    revisedDeparture () {
      if (this.reservation) return this.reservation.warningStatusDeparture === 'A_3'
      return false
    },
    modalOpened: {
      get () {
        return this.value
      },
      set (newName) {
        this.close()
        return newName
      }
    }
  }
}
</script>
<style lang="scss">
#Warning {
  .center-btn {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }
  .orange-btn {
    background: #ff9800 !important;
    opacity: 0.7;
    color: black;
  }
  .el-button {
    border-radius: 20px;
  }
}
</style>
