import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const MapiStore = {
  state: {},
  getters,
  mutations,
  actions,
}

export default MapiStore
