<template lang="pug">
#MissingInfo
  .flex-row.gap-7.justify-space-between
    .flex-row.gap-7
      .flex-col.gap-4

        .flex-row.align-center.gap-2
          span Meses hacia delante:
          el-select(v-model="weeks" style="width: 270px" :disabled="isFetching")
            el-option(v-for="week in weekOptions" :key="week.value" :value="week.value" :label="week.label")

        .flex-row.align-center.gap-2
          span Buscador:
          el-input(
            v-model="search"
            placeholder="Buscar por cualquier campo"
            clearable prefix-icon="el-icon-search"
          )
      .flex-col.gap-1.align-start
        span.mb-2 Simbología (Tiempo para check in)
        .flex-row.gap-2.align-center(v-for="item in legend")
          .circle(:style="{ backgroundColor: item.color, borderColor: item.borderColor }")
          span {{ item.label }}

      .flex-col.gap-1.align-start
        el-tooltip.item(effect="dark" content="Mostrar reservas con tickets parque y tren comprados" placement="top-start")
          el-switch.m-0(
            v-model="showWithTicketsDone"
            active-text="Mostrar reservas listas"
          )

    .flex-col.gap-1.align-start
      el-tooltip.item(effect="dark" content="Generar Excel en el rango de fecha seleccionado" placement="top-start")
        el-button.top-btn.ml-0.primary(@click="exportExcel" :loading="loadingExport") Exportar

  el-table.main-table.el-table--striped(
    :data="filteredItems"
    border height="1"
    header-cell-class-name="header-cell"
    cell-class-name="table-cell"
    :row-class-name="({ row, rowIndex }) => getRowClass({ row, rowIndex })"
  )

    el-table-column(prop="confirmationNumber" label="Número confirmación", sortable)
    el-table-column(prop="partyCode" label="Party Code", sortable)
    el-table-column(prop="agentUsername" label="Agente", sortable)
    el-table-column(prop="traveller" label="Nombre viajero")
    el-table-column(prop="mapiVisitDate" label="Fecha de preferencia")

    el-table-column(prop="checkin" label="Check in")
      template(#default="{ row }")
        .flex-row.align-center.gap-2
          .circle(:style="{ backgroundColor: getCircleColor(row), borderColor: getCircleBorder(row) }")
          span {{ row.checkin }}

    el-table-column(prop="missingFields" label="Información sin completar")

    el-table-column(prop="tickets" label="Tickets comprados" width="160px")
      template(#default="{ row }")
        .flex-row
          el-tooltip(v-if="row.hasTicketParque" effect="dark" content="Ticket parque comprado" placement="top")
            el-button.ticket-btn.checked-btn(data-dismiss="modal" type="primary")
              .el-icon-check
          el-tooltip(v-else effect="dark" content="Ticket parque sin comprar" placement="top")
            el-button.ticket-btn(data-dismiss='modal' type="primary")
              .el-icon-check

          el-tooltip(v-if="row.hasTicketTren" effect="dark" content="Ticket tren comprado" placement="top")
            el-button.ticket-btn.checked-btn(data-dismiss="modal" type="primary")
              .el-icon-check
          el-tooltip(v-else effect="dark" content="Ticket tren sin comprar" placement="top")
            el-button.ticket-btn(data-dismiss='modal' type="primary")
              .el-icon-check

          el-tooltip(v-if="row.hasTicketBus" effect="dark" content="Ticket bus comprado" placement="top")
            el-button.ticket-btn.checked-btn(data-dismiss="modal" type="primary")
              .el-icon-check
          el-tooltip(v-else effect="dark" content="Ticket bus sin comprar" placement="top")
            el-button.ticket-btn(data-dismiss='modal' type="primary")
              .el-icon-check

    el-table-column(prop="actions" label="" width="120px")
      template(#default="{ row }")
        .flex-row.gap-2
          el-tooltip(effect="dark" content="Ir a perfil del viajero" placement="top")
            el-button.action-btn(type="primary", @click="goToTp(row._tokenTp, row.confirmationNumber)")
              .el-icon-user-solid
          //- el-tooltip(v-if="row._reservation.mapiVisit && !row._reservation.mapiVisit.isApproved" effect="dark" content="Aprobar datos ingresados" placement="top")
          //-   el-button.action-btn(type="primary", @click="approveVisit(row)")
          //-     .el-icon-check
          //- el-tooltip(v-else-if="row._reservation.mapiVisit && row._reservation.mapiVisit.isApproved" effect="dark" content="Datos aprobados" placement="top")
          //-   .checked-btn
          //-     .el-icon-check

</template>

<script>
// prettier-ignore
import { Table, TableColumn, Select, Option, Button, DatePicker, Input } from 'element-ui'
import { mapActions } from 'vuex'
import moment from 'moment'

const legend = [
  { color: '#29ec67', border: '#346E46', label: 'Más de 6 meses' },
  { color: '#fff128', border: '#807B3B', label: 'Entre 2 y 6 meses' },
  { color: '#f34259', border: '#734148', label: 'Menos de 2 meses' },
]

const weekOptions = [
  { value: 4, label: '1 mes' },
  { value: 8, label: '2 meses' },
  { value: 13, label: '3 meses' },
  { value: 26, label: '6 meses' },
  { value: 52, label: '1 año' },
]

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
  },

  data() {
    this.nextRowStripe = false
    return {
      weeks: 26,
      search: null,
      items: [],
      isFetching: false,
      loadingExport: false,
      showWithTicketsDone: false,
    }
  },

  computed: {
    legend: () => legend,
    weekOptions: () => weekOptions,

    displayedItems() {
      return this.items.map((i) => ({
        confirmationNumber: i.confirmationNumber,
        partyCode: i.partyCode,
        agentUsername: i.agentUsername,
        traveller: i.traveller.reduce(
          (acc, t) => (acc += `${t.firstName} ${t.lastName}\n`),
          ''
        ),
        mapiVisitDate: i.mapiVisit?.visitDate
          ? this.formatDate(i.mapiVisit?.visitDate)
          : null,
        checkin: this.formatDate(i.checkin),
        missingFields: i.criticalFieldsNotCompleted.join(', '),
        hasTicketBus: i.mapiVisit?.hasTicketBus,
        hasTicketTren: i.mapiVisit?.hasTicketTren,
        hasTicketParque: i.mapiVisit?.hasTicketParque,
        _isApproved: i.mapiVisit?.isApproved,
        _tokenTp: i.tokenTravellerProfile,
        _reservation: i,
      }))
    },

    filteredItems() {
      let items = this.displayedItems
      if (!this.showWithTicketsDone) {
        items = items.filter((i) => {
          return !(i.hasTicketParque && i.hasTicketTren)
        })
      }

      if (!this.search) return items
      const search = this.search.toLowerCase()
      return items.filter((item) => {
        return Object.entries(item).some(([key, value]) => {
          if (key.startsWith('_')) return false
          return String(value).toLowerCase().includes(search)
        })
      })
    },
  },

  watch: {
    weeks() {
      this.fetch()
    },
  },

  methods: {
    ...mapActions([
      'futureReservationsMapi',
      'setApprovedMapi',
      'exportMapiData',
    ]),

    getRowClass({ row, rowIndex }) {
      const prevPartyCode = this.filteredItems[rowIndex - 1]?.partyCode || ''
      const currentPartyCode = row.partyCode

      if (prevPartyCode !== currentPartyCode) {
        this.nextRowStripe = !this.nextRowStripe
      }
      return this.nextRowStripe ? 'el-table__row--striped' : ''
    },

    formatDate(date) {
      return this.$options.filters.formatedDate(date)
    },

    getCircleItem(row) {
      if (row.hasTicketParque && row.hasTicketTren) return null

      const diff = moment(row.checkin, 'DD-MM-YYYY').diff(moment(), 'months')
      switch (true) {
        case diff > 6:
          return this.legend[0]
        case diff >= 2 && diff <= 6:
          return this.legend[1]
        case diff < 2 && diff >= 0:
          return this.legend[2]
        default:
          return null
      }
    },

    getCircleColor(row) {
      return this.getCircleItem(row)?.color || 'transparent'
    },

    getCircleBorder(row) {
      return this.getCircleItem(row)?.border || 'transparent'
    },

    async fetch() {
      let page = 1
      let hasNext = false
      this.isFetching = true
      this.items = []
      do {
        const data = await this.futureReservationsMapi({
          page: page,
          weeks: this.weeks,
          hotelId: 4,
          aditionalFields: 'criticalFieldsNotCompleted tokenTravellerProfile',
          forTicketsMode: false,
        })
        this.items.push(...data.objects)
        page += 1
        hasNext = data.hasNext
      } while (hasNext)
      this.isFetching = false
    },

    goToTp(token, confirmationNumber) {
      const routeData = this.$router.resolve({
        path: `/travellerProfile/modifyProfile/${token}/confirmationNumber/${confirmationNumber}`,
      })
      window.open(routeData.href, '_blank')
    },

    async approveVisit(row) {
      const mapiVisitId = row._reservation.mapiVisit?.id
      if (!mapiVisitId) return

      const result = await this.setApprovedMapi({
        mapiVisitId,
        approved: true,
        applyParty: false,
      })
      console.log(result)
      if (result) {
        this.$message.success('Visita a Machu Picchu aprobada')
        // this.fetch()
      }
    },

    async exportExcel() {
      this.loadingExport = true
      const params = {
        weeks: this.weeks,
      }
      const res = await this.exportMapiData(params)
      console.log(res)
      this.downloadFile(res)
      this.loadingExport = false
    },

    downloadFile(response) {
      const visitDate = moment(this.visitDate).format('YYYY-MM-DD')
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `mapi-tickets-${visitDate}.xls`)
      document.body.appendChild(link)
      link.click()
    },
  },

  created() {
    this.fetch()
  },
}
</script>

<style lang="scss" scoped>
#MissingInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0.5px #adb5bd solid;
}

.el-table::v-deep {
  .header-cell {
    padding: 4px;
  }

  .table-cell > .cell {
    word-break: normal;
  }
}

.top-btn {
  background: #1b1b1b;
  border-color: #1b1b1b;
  color: #fff;
}

.action-btn {
  background: #1b1b1b;
  border-color: #1b1b1b;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  border-radius: 50%;
}

.ticket-btn {
  color: #dfdfdf;
  border: 1px #dfdfdf solid;
  background-color: transparent;
  font-size: 20px;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &:hover, &:focus {
    color: #dfdfdf;
    border: 1px #dfdfdf solid;
    background-color: transparent;
  }
}

.checked-btn {
  color: #29ec67;
  border: 1px #29ec67 solid;
  font-size: 20px;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover, &:focus {
    color: #29ec67;
    border: 1px #29ec67 solid;
    background-color: transparent;
  }
}

.main-table {
  flex: auto;
  height: calc(100vh - 265px) !important;
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F5F5F5;
}
</style>
